.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    position: relative;
    font-family: 'Plus Jakarta Sans';
    padding-bottom: 50px;
}

.wrapper.light {
    .titleSpecialBlue,
    .titleSpecialTurquoise {
        color: #0f73ef;
    }
    .tablePoint {
        background-image: url('../../../../assets/img/tableListPointLight.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .recommendedSystemTableBorder {
        & linearGradient:nth-child(7) {
            stop {
                stop-color: #0f73ef;
            }
        }
    }
    .rectangle {
        linearGradient stop {
            stop-color: #0f73ef;
        }
    }
}

.blockWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 33px;
}

.title {
    font-size: 34px;
    height: 43px;
    font-weight: 700;
}

.titleSpecialBlue {
    background-image: linear-gradient(90.31deg, rgba(6, 240, 254, 0.94) -50.26%, rgba(2, 92, 241, 0.94) 88.81%);
    background-clip: text;
    color: transparent;
}

.titleSpecialTurquoise {
    background-image: linear-gradient(90.31deg, rgba(6, 240, 254, 0.94) 96.26%, rgba(2, 92, 241, 0.94) 118.81%);
    background-clip: text;
    color: transparent;
}

.text {
    width: 790px;
    height: 108px;
    font-weight: 500;
    font-size: 14px;
    line-height: 31.5px;
}

.recommendedSystemTable {
    position: relative;
    width: 1015px;
    height: 471px;
    backdrop-filter: blur(30.8036px);
    background: linear-gradient(96.82deg, rgba(255, 255, 255, 0.06) 17.79%, rgba(255, 255, 255, 0.025) 105.61%);
    box-shadow: 0px 3.08036px 18.4822px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.recommendedSystemTableBorder {
    position: absolute;
    bottom: -23px;
    left: -23px;
    width: 1141px;
    height: 494px;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
}
.rectangle {
    position: absolute;
    top: -23px;
    right: -28px;
    width: 246px;
    height: 185px;
    z-index: 1000;
}

.tableOptionWrapper {
    display: flex;
    gap: 18px;
}

.tablePoint {
    width: 18px;
    height: 18px;
    background-image: url('../../../../assets/img/tableListPoint.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.tableOptionText {
    width: 595px;
    font-size: 14px;
    line-height: 21px;
}

.rightBackgroundLight {
    width: 1164px;
    height: 724px;
    position: absolute;
    right: -438px;
    bottom: 0;
    background-image: url('../../../../assets/img/systemReqBgRightLignt.svg');
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(150px);
    z-index: -1;
}

@media (max-width: 1100px) {
    .text {
        width: 60%;
        height: auto;
    }

    .recommendedSystemTable {
        padding: 20px;
        width: 80%;
        height: auto;
        align-items: flex-start;
    }
    .recommendedSystemTableBorder,
    .rectangle {
        display: none;
    }

    .tableOptionText {
        width: 100%;
    }
    .recommendedSmallBox {
        top: -50px;
        left: -10px;
    }
}

@media (max-width: 620px) {
    .wrapper {
        padding-bottom: 70px;
    }
    .wrapper.light {
        .recommendedSystemTable {
            background: url('../../../../assets/img/systemReqBGmobileLight.svg');
            background-repeat: no-repeat;
            background-position: center;
            height: 498px;
        }
    }
    .recommendedSystemTable {
        width: 304px;
        height: 505px;
        background: url('../../../../assets/img/systemReqBGmobile.svg');
        background-repeat: no-repeat;
        background-position: center;
        gap: 10px;
        align-items: center;
        padding: 0;
    }
    .tableOptionWrapper {
        gap: 10px;
    }
    .tableOptionText {
        width: 237px;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
    }
    .text {
        width: 304px;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
    }
    .wrapper {
        .blockWrapper:nth-child(1) {
            margin-bottom: 18px;
            .title:first-child {
                font-weight: 700;
                font-size: 18px;
                line-height: 23px;
            }
        }
        .blockWrapper:nth-child(2) {
            .title:first-child {
                font-weight: 700;
                font-size: 18px;
                line-height: 20px;
            }
        }
    }
    .title {
        height: 23px;
    }
    .blockWrapper {
        gap: 18px;
    }
}
