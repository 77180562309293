@import './scss';
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 59px;
    padding-bottom: 20px;
}

.wrapper.light {
    .title span {
        color: #0f73ef;
    }
    .concept {
        background: linear-gradient(90deg, rgb(15, 115, 239) 0%, rgb(255, 255, 255) 50%, rgb(15, 115, 239) 100%);
        div {
            background: #ffffff;
        }
    }
    .rightBorderBg,
    .leftBorderBg {
        & path {
            stroke: #0f73ef;
        }
    }
}

.descriptionBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 29px;
}

.title {
    font-size: 34px;
    font-weight: 700;
    line-height: 42.84px;
    height: 43px;
}

.title span {
    background-image: linear-gradient(91.11deg, #025cf1 30.82%, #05f0fd 130.71%, #7d48e4 209.57%);
    background-clip: text;
    color: transparent;
}

.cubes {
    width: 110px;
    height: 246px;
    background-image: url('../../../../assets/img/networkCubes.svg');
}

.textBlockContent {
    position: relative;
    width: 1015px;
    height: 190px;
    background: linear-gradient(96.82deg, rgba(255, 255, 255, 0.06) 17.79%, rgba(255, 255, 255, 0.025) 105.61%);
    box-shadow: 0px 2.9298px 17.5788px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(62px);
}

.textBlockContent p {
    font-weight: 600;
    font-size: 14px;
    line-height: 25.02px;
    margin: 25px 111px;
}
.rightBorderBg {
    position: absolute;
    right: -23px;
    top: -23px;
}
.leftBorderBg {
    position: absolute;
    left: -23px;
    bottom: -23px;
}
.gxdIncludesPointsBlock {
    width: 1215px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 47px;
}

.gxdIncludesPointsBlock p {
    width: 706px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.gxdPointsList {
    width: 679px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.gxdOptionWrapper {
    @extend %flex-center;
    width: 170px;
    height: 90px;
    background-image: url('../../../../assets/img/networkOptionBackground.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.gxdOptionWrapper span {
    width: 120px;
    line-height: 20.16px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.conceptsWrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .concepts {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 600px;
    }
    .conceptsBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 120px;
        &:first-child {
            .concept:first-child {
                transform: skewX(10deg);
                span {
                    transform: skewX(-10deg);
                }
            }
            .concept:last-child {
                transform: skewX(-10deg);
                span {
                    transform: skewX(10deg);
                }
            }
        }
        &:last-child {
            .concept:first-child {
                transform: skewX(-10deg);
                span {
                    transform: skewX(10deg);
                }
            }
            .concept:last-child {
                transform: skewX(10deg);
                span {
                    transform: skewX(-10deg);
                }
            }
        }
    }
    .concept {
        background: linear-gradient(50deg, #06f0fe 20%, #025cf1 70%);
        padding: 1.5px;

        div {
            width: 202px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: black;
            height: 40px;
            box-sizing: border-box;
            user-select: none;
        }

        &:last-child {
            margin-top: 20px;
        }
    }
}

@media (max-width: 1250px) {
    .gxdIncludesPointsBlock p {
        width: 60%;
        height: auto;
    }
}

@media (max-width: 830px) {
    .textBlockContent {
        width: 304px;
        height: 358px;
        font-weight: 500;
        font-size: 14px;

        background: linear-gradient(96.82deg, rgba(255, 255, 255, 0.06) 17.79%, rgba(255, 255, 255, 0.025) 105.61%);
        box-shadow: 0px 2.9298px 17.5788px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(62px);
    }
    .textBlockContent p {
        margin: 0px;
        font-size: 12px;
    }
    .mobileText {
        padding: 10px 10px 5px 15px;
        display: block;
        line-height: 186%;
    }
    .gxdIncludesPointsBlock {
        width: 100%;
    }
    .gxdIncludesPointsBlock p {
        font-size: 14px;
        width: 304px;
        height: auto;
        margin-bottom: 18px;
    }
    .cubes,
    .rightBorderBg,
    .leftBorderBg {
        display: none;
    }
    .conceptsWrap {
        .concepts {
            flex-direction: column;
        }
    }
    .conceptsWrap {
        .conceptsBlock {
            &:first-child {
                margin: 0 0 25px 0;
            }
            &:last-child {
                .concept:first-child {
                    transform: skewX(10deg);
                    span {
                        transform: skewX(-10deg);
                    }
                }
                .concept:last-child {
                    transform: skewX(-10deg);
                    span {
                        transform: skewX(10deg);
                    }
                }
            }
        }
    }
}

@media (max-width: 720px) {
    .wrapper {
        gap: 10px;
        padding-bottom: 70px;
    }
    .descriptionBlock {
        gap: 24px;
    }
    .title {
        font-size: 24px;
        height: 30px;
    }

    .gxdIncludesPointsBlock {
        gap: 10px;
    }

    .gxdOptionWrapper span {
        font-size: 12px;
        background-size: contain;
    }

    .conceptsBlock {
        &:nth-child(1) {
            &:first-child {
                .concept:first-child {
                    transform: skewX(0);

                    span {
                        transform: skewX(0);
                    }
                }
                .concept:last-child {
                    transform: skewX(0deg);
                    span {
                        transform: skewX(0);
                    }
                }
            }
        }
    }
    .conceptsWrap {
        .conceptsBlock {
            &:last-child {
                .concept:first-child {
                    transform: skewX(0);
                    span {
                        transform: skewX(0);
                    }
                }
                .concept:last-child {
                    transform: skewX(0);
                    span {
                        transform: skewX(0);
                    }
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        height: 23px;
    }
    .gxdIncludesPointsBlock p {
        width: 304px;
        height: auto;
        font-weight: 600;
        font-size: 12px;
    }
}
