.cube {
    position: absolute;
    top: -50px;
    left: -40px;
    z-index: 2;
    overflow: hidden;
    .side {
        transition: 3s;
    }
    &.blue {
        .cubeY {
            fill: #2f3fd3;
        }
        .cubeX {
            fill: #bdebff;
        }
        .cubeZ {
            fill: #4298e8;
        }
    }
    &.red {
        .cubeY {
            fill: #d32f2f;
        }
        .cubeX {
            fill: #ffbdbd;
        }
        .cubeZ {
            fill: #dc5e5e;
        }
    }
    &.green {
        .cubeY {
            fill: #2fd349;
        }
        .cubeX {
            fill: #c7ffbd;
        }
        .cubeZ {
            fill: #2fd349;
        }
    }

    &.loading {
        animation-duration: 3s;
        animation-name: loading;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    &.error {
        animation-duration: 6s;
        animation-name: error;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.waiting {
        animation-duration: 6s;
        animation-name: waiting;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.disappear {
        animation-duration: 1.5s;
        animation-name: disappear;
        animation-timing-function: cubic-bezier(1, 0.06, 1, 0.99);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.appear {
        animation-duration: 1.5s;
        animation-name: appear;
        animation-timing-function: cubic-bezier(0, 0.15, 1, 1);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.grab {
        animation-duration: 1s;
        animation-name: grab;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        // animation-iteration-count: infinite;
    }
    &.success {
        animation-duration: 3s;
        animation-name: success;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.afterWaiting {
        &.appear {
            animation: appear 1.5s forwards, waiting 6s 2.2s infinite;
        }
    }
    &.afterError {
        transform: translate(15px, 285px) rotate(-35deg);

        &.loading {
            animation: restore 3s forwards, loading 3s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: restore 3s forwards, waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: disappearAfterError;
            overflow: hidden;
        }
        &.appear {
            animation-name: appearToError;
        }
    }
    &.afterSuccess {
        transform: translate(0, 0);

        &.loading {
            animation: destroy 3s forwards, loading 3s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: destroy 3s forwards, waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: disappearAfterSuccess;
            overflow: hidden;
        }
        &.appear {
            animation-name: appearToSuccess;
        }
    }
}

@keyframes loading {
    0% {
        transform: scale(1.2) rotate(0deg);
    }
    25% {
        transform: scale(0.5) rotate(-15deg);
    }
    50% {
        transform: scale(0.7) rotate(0deg);
    }
    75% {
        transform: scale(0.5) rotate(15deg);
    }
    100% {
        transform: scale(1.2);
    }
}
@keyframes error {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15% {
        transform: translate(0px, 285px) rotate(-70deg);
    }
    35%,
    100% {
        transform: translate(15px, 285px) rotate(-35deg);
    }
}
@keyframes restore {
    0% {
        transform: translate(15px, 285px) rotate(-35deg);
    }
    20% {
        transform: translate(0px, 285px) rotate(-70deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes waiting {
    0% {
        transform: translate(0, 0);
    }
    35% {
        transform: translate(0, 30px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes success {
    0% {
        transform: translate(0, 0);
    }
    25%,
    100% {
        transform: translate(0, 160px);
    }
}
@keyframes destroy {
    0%,
    20% {
        transform: translate(0, 160px);
    }
    35%,
    100% {
        transform: translate(0, 0);
    }
}

@keyframes disappear {
    0% {
        width: 83px;
        transform: translate(0, 0);
    }
    70% {
        width: 83px;
    }
    75%,
    100% {
        width: 0px;
        transform: translate(270px, 0);
    }
}
@keyframes disappearAfterSuccess {
    0% {
        width: 83px;
        transform: translate(0, 160px);
    }
    70% {
        width: 83px;
    }
    75%,
    100% {
        width: 0px;
        transform: translate(270px, 0);
    }
}
@keyframes disappearAfterError {
    0% {
        transform: translate(15px, 285px) rotate(-35deg);
    }
    25% {
        width: 83px;
    }
    35%,
    100% {
        width: 0px;
        transform: translate(270px, 0);
    }
}

@keyframes appear {
    0%,
    72% {
        overflow: hidden;
        width: 0;
        transform: translate(270px, 0);
    }
    76% {
        width: 83px;
    }
    90%,
    100% {
        width: 83px;
        transform: translate(0, 0);
    }
}
@keyframes appearToError {
    0%,
    72% {
        overflow: hidden;
        width: 0;
        transform: translate(270px, 0);
    }
    76% {
        width: 83px;
    }
    90%,
    100% {
        transform: translate(15px, 285px) rotate(-35deg);
    }
}

@keyframes appearToSuccess {
    0%,
    30% {
        width: 0;
        transform: translate(270px, 0);
    }
    34% {
        width: 83px;
    }
    50%,
    100% {
        width: 83px;
        transform: translate(0, 160px);
    }
}

@keyframes grab {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(0px, -100px);
    }
    40% {
        transform: translate(0px, -50px);
    }
    60% {
        transform: translate(0px, 110px);
    }
    70% {
        transform: translate(0px, 140px);
    }
    74%,
    100% {
        transform: translate(0px, 100px);
    }
}
