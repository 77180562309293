@import './L.scss';
@import './T.scss';
@import './B.scss';
@import './R.scss';
.cube {
    position: absolute;
    .side {
        transition: 3s;
    }
    &.blue {
        .cubeY {
            fill: #2f3fd3;
        }
        .cubeX {
            fill: #bdebff;
        }
        .cubeZ {
            fill: #4298e8;
        }
    }
    &.red {
        .cubeY {
            fill: #d32f2f;
        }
        .cubeX {
            fill: #ffbdbd;
        }
        .cubeZ {
            fill: #dc5e5e;
        }
    }
    &.green {
        &::before {
            content: '';
            position: absolute;
            height: 30px;
            width: 30px;
            background: transparent;
            border-radius: 50%;
            border-right: 2px solid transparent;
            opacity: 0.4;
            transform: translate(-10px, 8px) rotate(210deg);
            animation-name: before;
            animation-duration: 4s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-delay: 2.5s;
        }

        &::after {
            content: '';
            position: absolute;
            height: 30px;
            width: 30px;
            background: transparent;
            border-radius: 50%;
            border-right: 2px solid transparent;
            opacity: 0.4;
            transform: translate(-79px, 8px) rotate(210deg);
            animation-name: after;
            animation-duration: 4s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-delay: 2s;
        }
        .cubeY {
            fill: #2fd349;
        }
        .cubeX {
            fill: #c7ffbd;
        }
        .cubeZ {
            fill: #2fd349;
        }
    }
}

@keyframes after {
    0% {
        height: 30px;
        width: 30px;
        border-right: 2px solid #2fd349;
        transform: translate(-79px, 8px) rotate(210deg);
        opacity: 0.4;
    }
    100% {
        height: 100px;
        width: 100px;
        opacity: 0;
        border-right: 2px solid #2fd349;
        transform: translate(-165px, -60px) rotate(210deg);
    }
}

@keyframes before {
    0% {
        height: 30px;
        width: 30px;
        border-right: 2px solid #2fd349;
        transform: translate(-10px, 8px) rotate(210deg);
        opacity: 0.4;
    }
    100% {
        height: 100px;
        width: 100px;
        opacity: 0;
        border-right: 2px solid #2fd349;
        transform: translate(-95px, -60px) rotate(210deg);
    }
}
