.RT {
    top: -100px;
    right: -100px;

    &.loading {
        animation-duration: 6s;
        animation-name: RT_Loading;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }

    &.error {
        animation-duration: 6s;
        animation-name: RT_Error;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.waiting {
        animation-duration: 6s;
        animation-name: RT_Waiting;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.disappear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: RT_Disappear;
        animation-timing-function: cubic-bezier(0.92, 0.04, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.appear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: RT_Appear;
        animation-timing-function: cubic-bezier(0.61, 0.02, 0.89, 0.91);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.grab {
        animation-duration: 1s;
        animation-name: RT_Grab;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        // animation-iteration-count: infinite;
    }
    &.success {
        animation-duration: 3s;
        animation-name: RT_Success;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.afterWaiting {
        &.appear {
            animation: RT_Appear 1.5s forwards, RT_Waiting 6s 2.2s infinite;
        }
    }
    &.afterError {
        transform: translate(-35px, 415px) rotate(75deg);

        &.loading {
            animation: RT_Restore 3s forwards, RT_Loading 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: RT_Restore 3s forwards, RT_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: RT_DisappearAfterError;
            overflow: hidden;
        }
        &.appear {
            animation-name: RT_AppearToError;
            overflow: hidden;
        }
    }
    &.afterSuccess {
        transform: translate(-77px, 16px) rotate(-120deg);
        &.loading {
            animation: RT_Destroy 3s forwards, RT_Loading 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: RT_Destroy 3s forwards, RT_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: RT_DisappearAfterSuccess;
        }
        &.appear {
            animation-name: RT_AppearAfterSuccess;
        }
    }
}
@keyframes RT_Loading {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    25% {
        transform: translate(-125px, 25px) rotate(-90deg);
    }
    50% {
        transform: translate(-160px, 145px) rotate(0);
    }
    75% {
        transform: translate(-25px, 125px) rotate(90deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes RT_Error {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15% {
        transform: translate(0px, 400px) rotate(135deg);
    }
    18% {
        transform: translate(-15px, 405px) rotate(75deg);
    }
    35%,
    100% {
        transform: translate(-35px, 415px) rotate(75deg);
    }
}
@keyframes RT_Restore {
    0% {
        transform: translate(-35px, 415px) rotate(75deg);
    }
    17% {
        transform: translate(-15px, 405px) rotate(75deg);
    }
    20% {
        transform: translate(0px, 400px) rotate(135deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes RT_Waiting {
    0% {
        transform: translate(0, 0);
    }
    60% {
        transform: translate(0, 40px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes RT_Success {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    40% {
        transform: translate(100px, 0) rotate(0deg);
    }
    55%,
    100% {
        transform: translate(-77px, 16px) rotate(-120deg);
        overflow: inherit;
    }
}
@keyframes RT_Destroy {
    0% {
        transform: translate(-77px, 16px) rotate(-120deg);
    }
    10%,
    25% {
        transform: translate(100px, 0) rotate(0deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes RT_Disappear {
    0% {
        width: 43px;
        transform: translate(0, 0);
    }
    62% {
        width: 43px;
    }
    65%,
    100% {
        width: 0px;
        transform: translate(130px, 40px);
    }
}
@keyframes RT_DisappearAfterSuccess {
    0% {
        width: 43px;
        transform: translate(-77px, 16px) rotate(-120deg);
    }
    62% {
        width: 43px;
    }
    65%,
    100% {
        width: 0px;
        transform: translate(130px, 0) rotate(0deg);
    }
}
@keyframes RT_Appear {
    0%,
    81% {
        width: 0px;
        transform: translate(130px, 40px);
        overflow: hidden;
    }
    84% {
        width: 43px;
    }
    90%,
    100% {
        width: 43px;
        transform: translate(0, 0);
        overflow: inherit;
    }
}
@keyframes RT_AppearToError {
    0%,
    81% {
        width: 0px;
        transform: translate(130px, 40px);
        overflow: hidden;
    }
    84% {
        width: 43px;
    }
    90%,
    100% {
        width: 43px;
        transform: translate(-35px, 415px) rotate(75deg);
    }
}
@keyframes RT_AppearAfterSuccess {
    0%,
    71% {
        width: 0px;
        transform: translate(130px, 40px);
        overflow: hidden;
    }
    74% {
        width: 43px;
    }
    95%,
    100% {
        width: 43px;
        transform: translate(-77px, 16px) rotate(-120deg);
        overflow: inherit;
    }
}

@keyframes RT_DisappearAfterError {
    0% {
        transform: translate(-35px, 415px) rotate(75deg);
    }
    18% {
        width: 43px;
    }
    35%,
    100% {
        width: 0px;
        transform: translate(130px, 0) rotate(0deg);
    }
}

@keyframes RT_Grab {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(10px, 72px);
    }
    40% {
        transform: translate(-50px, 232px);
    }
    60%,
    100% {
        transform: translate(-40px, 382px);
    }
}
