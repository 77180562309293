.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    padding: 0 0 70px 0;
}

.wrapper.light {
    .specialTitle {
        color: #0f73ef;
    }
    .ipfsUpdateButton {
        color: white;
        background: #0f73ef;
    }
    .boxWrapper {
        background: linear-gradient(90deg, rgb(15, 115, 239) 0%, rgb(255, 255, 255) 50%, rgb(15, 115, 239) 100%);
        padding: 1.5px;
    }
    .boxContent {
        background: white;
        color: #1e1d1f;
    }
    .copyLinkIcon {
        width: 24px;
        height: 30px;
        background-position: center;
        background-image: url('../../../../assets/img/copyLinkIconLight.svg');
    }
}

h2.title {
    font-size: 34px;
    font-weight: 700;
    line-height: 42.84px;
    width: 568px;
    text-align: center;
}
h3.title {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.28px;
    width: 564px;
    text-align: center;
}
.text {
    width: 600px;
    font-size: 16px;
    font-weight: 500;
    line-height: 35.28px;
}
.specialTitle {
    background-image: linear-gradient(91.11deg, #025cf1 -3.82%, #05f0fd 156.71%, #7d48e4 159.57%);
    background-clip: text;
    color: transparent;
}
.ipfsUpdateButton {
    font-size: 18px;
    user-select: none;
    cursor: pointer;
    padding: 3px 13px;
    margin: 0 43px 0 10px;
    background: linear-gradient(250.09deg, #14bdfe 36.7%, #495aff 109.27%);
    border-radius: 8px;
}

.boxWithTitle {
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
}
.boxWrapper {
    width: 799px;
    height: 64px;
    position: relative;
    background: linear-gradient(50deg, #06f0fe 20%, #025cf1 70%);
    padding: 3px;
}
.boxContent {
    height: 100%;
    box-sizing: border-box;
    background: #1e1d1f;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
}
.boxContent p {
    font-size: 18px;
    font-weight: 400;
    line-height: 33.48px;
}
.copyLinkIcon {
    width: 24px;
    height: 30px;
    background-position: center;
    background-image: url('../../../../assets/icons/copyLinkIcon.svg');
}

@media (max-width: 910px) {
    .text {
        width: 80%;
    }
    .boxWrapper {
        width: 90%;
        height: auto;
    }
    .boxContent {
        padding: 16px;
    }
    .title {
        width: 502px;
        margin: 0 auto;
    }
}

@media (max-width: 520px) {
    .text {
        width: 300px;
        font-weight: 500;
        font-size: 14px;
        line-height: 168%;
    }
    .ipfsUpdateButton {
        font-weight: 500;
        font-size: 14px;
        line-height: 168%;
    }
    .boxWrapper {
        width: 98%;
        height: auto;
    }
    .boxContent {
        padding: 16px 14px;
    }
    .boxContent p {
        font-weight: 400;
        font-size: 10px;
        line-height: 186%;
    }
    h2.title {
        width: 100%;
        text-align: center;
        font-size: 18px;
        line-height: 23px;
    }
    h3.title {
        font-size: 16px;
        line-height: 20px;
        width: 302px;
    }
    .copyLinkIcon {
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
