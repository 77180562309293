@import './scss';
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 80px;
}

.text {
    font-family: 'Plus Jakarta Sans';
    position: absolute;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #10dbfe;
    user-select: none;
}
.text.light {
    color: $light-color-blue;
}

.light.container::before {
    content: '';
    background: url('./assets/rewardDesktopLight.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    height: 60px;
    width: calc(90vw - 40px);
}

.container::before {
    content: '';
    background: url('./assets/rewardDesktop.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    height: 60px;
    width: calc(90vw - 40px);
}

@media (max-width: 768px) {
    .container::before {
        width: calc(85vw - 25px);
    }
}

@media (max-width: 480px) {
    .container::before {
        background: url('./assets/reward480.svg');
        width: calc(80vw - 20px);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}
