.T {
    top: -200px;
    left: -40px;

    &.past-error {
        margin: 400px;
        transform: translate(-90px, 445px) rotate(-275deg);
    }

    &.loading {
        animation-duration: 6s;
        animation-name: T_Loading;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.error {
        animation-duration: 6s;
        animation-name: T_Error;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }
    &.waiting {
        animation-duration: 6s;
        animation-name: T_Waiting;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.disappear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: T_Disappear;
        animation-timing-function: cubic-bezier(0.92, 0.04, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.appear {
        animation-duration: 1.5s;
        animation-name: T_Appear;
        animation-timing-function: cubic-bezier(0.6, 0.24, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.grab {
        animation-duration: 1s;
        animation-name: T_Grab;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.success {
        animation-duration: 3s;
        animation-name: T_Success;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.afterWaiting {
        &.appear {
            animation: T_Appear 1.5s forwards, T_Waiting 6s 2.2s infinite;
        }
    }
    &.afterError {
        transform: translate(-90px, 445px) rotate(-275deg);

        &.loading {
            animation: T_Restore 3s forwards, T_Loading 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: T_Restore 3s forwards, T_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: T_DisappearAfterError;
            overflow: hidden;
        }
        &.appear {
            animation-name: T_AppearToError;
            overflow: hidden;
        }
    }
    &.afterSuccess {
        transform: translate(-30px, 150px) rotate(0deg);

        &.loading {
            animation: T_Destroy 3s forwards, T_Loading 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: T_Destroy 3s forwards, T_Waiting 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: T_DisappearAfterSuccess;
        }
        &.appear {
            animation-name: T_AppearAfterSuccess;
        }
    }
}
@keyframes T_Loading {
    0% {
        transform: translate(0px, 0px);
    }
    25% {
        transform: translate(0px, 30px);
    }
    50% {
        transform: translate(0px, 0px);
    }
    75% {
        transform: translate(0px, 30px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}
@keyframes T_Error {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15% {
        transform: translate(0px, 360px) rotate(-135deg);
    }
    20% {
        transform: translate(-25px, 375px) rotate(-180deg);
    }
    25% {
        transform: translate(-40px, 375px) rotate(-195deg);
    }
    35%,
    100% {
        transform: translate(-90px, 445px) rotate(-275deg);
    }
}
@keyframes T_Restore {
    0% {
        transform: translate(-90px, 445px) rotate(-275deg);
    }
    10% {
        transform: translate(-40px, 375px) rotate(-195deg);
    }
    15% {
        transform: translate(-25px, 375px) rotate(-180deg);
    }
    20% {
        transform: translate(0px, 360px) rotate(-135deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes T_Waiting {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 30px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes T_Success {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    35% {
        transform: translate(-100px, 0) rotate(0deg);
    }
    50%,
    100% {
        transform: translate(-30px, 150px) rotate(0deg);
        overflow: inherit;
    }
}
@keyframes T_Destroy {
    0%,
    10% {
        transform: translate(-30px, 150px) rotate(0deg);
    }
    20% {
        transform: translate(-100px, 0) rotate(0deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes T_Disappear {
    0% {
        width: 69px;
        transform: translate(0, 0);
    }
    75% {
        width: 69px;
    }
    80%,
    100% {
        width: 0px;
        transform: translate(240px, 120px);
    }
}
@keyframes T_DisappearAfterSuccess {
    0% {
        width: 69px;
        transform: translate(-30px, 150px) rotate(0deg);
    }

    85% {
        width: 69px;
    }
    90%,
    100% {
        transform: translate(240px, 170px);
        width: 0px;
    }
}
@keyframes T_Appear {
    0%,
    78% {
        width: 0px;
        transform: translate(240px, 120px);
        overflow: hidden;
    }
    80% {
        width: 69px;
    }
    90% {
        width: 69px;
        transform: translate(0, 0);
        overflow: inherit;
    }
}
@keyframes T_AppearToError {
    0%,
    78% {
        width: 0px;
        transform: translate(240px, 120px);
        overflow: hidden;
    }
    80% {
        width: 69px;
    }
    90% {
        width: 69px;
        transform: translate(-90px, 445px) rotate(-275deg);
    }
}
@keyframes T_AppearAfterSuccess {
    0%,
    78% {
        width: 0px;
        transform: translate(240px, 120px);
        overflow: hidden;
    }
    80% {
        width: 69px;
    }
    90% {
        width: 69px;
        transform: translate(-30px, 150px) rotate(0deg);
        overflow: inherit;
    }
}
@keyframes T_DisappearAfterError {
    0% {
        transform: translate(-90px, 445px) rotate(-275deg);
    }

    55% {
        width: 69px;
    }
    70%,
    100% {
        transform: translate(240px, 170px);
        width: 0px;
    }
}

@keyframes T_Grab {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(-80px, 160px);
    }
    40% {
        transform: translate(-60px, 250px);
    }
    60% {
        transform: translate(-90px, 400px);
    }
    70% {
        transform: translate(-90px, 420px);
    }
    78%,
    100% {
        transform: translate(-70px, 400px);
    }
}
