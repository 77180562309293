@import './scss';
.wrapper {
    display: grid;
    grid-template-columns: 490px 1fr;
    margin: auto;
    align-items: center;
    justify-items: self-end;
    position: relative;
    padding: 65px 108px;
}

.wrapper.light {
    background: #f0f4fc;

    & .iconInEmailBlock path,
    .socialIcon path,
    .socialLinkedin rect {
        fill: $light-color-blue;
    }

    & .socialLinkedin path {
        fill: #f0f4fc;
    }
    & .navMenuWrapper a {
        color: $light-color-second;
    }
    & .usefulPages a,
    .joinUsTitle,
    .joinUsReserved {
        color: $light-color-third;
    }
    & .emailBlock {
        color: $light-color-second;
    }
}
.emailBlock {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 100px;
}

.iconInEmailBlock {
    grid-area: icon;
}

.inputContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.navMenuWrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.navItem {
    text-decoration: none;
    color: white;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.joinUsBlock {
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 20px;
}

.joinUsTitle {
    color: $dark-color-second;
    letter-spacing: 0.03em;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.joinUsReserved {
    color: $dark-color-second;
    position: absolute;
    width: 100%;
    bottom: 35px;
    text-align: center;
    margin-top: 30px;
}

.socialIconsContainer {
    display: flex;
    gap: 10px;
}

.socialIconsContainer:first-child {
    margin: 0 0 10px 0;
}
.socialIcon {
    cursor: pointer;
    width: 36px;
    height: 36px;
    grid-area: icon;
}

.socialLinkedin {
    cursor: pointer;
    width: 36px;
    height: 36px;
    grid-area: icon;
}

.usefulPages {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.usefulPages a {
    text-decoration: none;
    color: $dark-color-second;
    font-weight: 400;
}

.inputContainer {
    display: grid;
    grid-template-columns: 1fr 178px;
    align-items: center;
}

.secondMenu {
    width: 650px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1600px) {
    .wrapper {
        padding: 65px 48px;
    }
    .secondMenu {
        width: 600px;
    }
}

@media (min-width: 1151px) and (max-width: 1280px) {
    .secondMenu {
        width: 600px;
    }
}

@media (min-width: 930px) and (max-width: 1150px) {
    .wrapper {
        position: relative;
    }
    .secondMenu {
        display: grid;
        grid-template-columns: 160px 150px;
        width: 350px;
    }
    .joinUsBlock {
        position: absolute;
        bottom: 50px;
        left: 36px;
    }
    .joinUsBlock div {
        display: flex;
    }
    .socialIconsContainer:nth-child(1) {
        margin-right: 10px;
    }
}

@media (max-width: 929px) {
    .wrapper {
        padding: 65px 0 150px 36px;
        grid-template-columns: 600px;
    }
    .secondMenu {
        width: 600px;
    }
    .inputContainer {
        display: flex;
        flex-direction: column;
    }
    .inputContainer > input {
        padding-right: 20px;
    }
    .inputContainer > div:first-child {
        width: 100%;
        border: none;
        background: #252525;
    }
    .inputContainer > div:first-child::before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        border-bottom: 22px solid transparent;
        border-right: 22px solid #12101a;
        border-top: 0 solid transparent;
    }

    .wrapper.light {
        & .inputContainer > div:first-child {
            background: #ffffff;
        }
        & .inputContainer > div:first-child::before {
            border-right: 22px solid #f0f4fc;
        }
    }
    .inputContainer > div:first-child {
        width: 100%;
        margin-bottom: 24px;
    }
}

@media (max-width: 650px) {
    .wrapper {
        justify-items: center;
        grid-template-columns: 1fr;
        margin: 0 auto;
        padding: 0;
    }
    .emailBlock {
        width: 312px;
    }
    .secondMenu {
        display: grid;
        grid-template-columns: 170px 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 30px;
        margin: 0 0 80px 0;
        width: 330px;
    }
    .joinUsBlock {
        width: 220px;
    }
    .joinUsReserved {
        text-align: center;
    }
}

@media (max-width: 350px) {
    .emailBlock {
        width: 280px;
    }
    .secondMenu {
        width: 280px;
    }
}
