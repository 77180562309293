@import './scss/';
.button_container {
    width: var(--width) px;
    height: 65px;
    position: relative;
    cursor: pointer;
    user-select: none;
    clip-path: polygon(0 0, 93.5% 0, 100% 18%, 100% 100%, 6% 100%, 0 83%);
}

.button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button_container.loading {
    cursor: progress;
}

.button_container.disabled {
    cursor: not-allowed;
}

.button_text {
    width: var(--width);
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.01em;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    z-index: 2;
}

.dots {
    display: flex;
    position: absolute;
    left: 130px;
}

.dot {
    opacity: 0;
    color: white;
}

.first {
    animation-duration: 3s;
    animation-name: first;
    animation-iteration-count: infinite;
}

.second {
    animation-duration: 3s;
    animation-name: second;
    animation-iteration-count: infinite;
}

.third {
    animation-duration: 3s;
    animation-name: third;
    animation-iteration-count: infinite;
}

@keyframes first {
    20% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    95% {
        opacity: 0;
    }
}

@keyframes second {
    25% {
        opacity: 0;
    }
    45% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    95% {
        opacity: 0;
    }
}

@keyframes third {
    50% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    95% {
        opacity: 0;
    }
}

.glitch {
    font-size: 20px;
    font-weight: 700;
    user-select: none;
    color: white;
}

.glitch span {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: var(--width);
}

.buttonBorder:hover .glitch span:first-child {
    animation: glitch 650ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
    transform: translate(-0.025em, -0.0125em);
    opacity: 0.8;
}

.buttonBorder:hover .glitch span:last-child {
    animation: glitch 375ms infinite;
    clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
    transform: translate(0.0125em, 0.025em);
    opacity: 0.8;
}

.inInput {
    margin: 0 0 0 -20px;
}

@keyframes glitch {
    0% {
        text-shadow: 0.05em 0 0 rgb(5, 240, 253), -0.05em -0.025em 0 rgba(255, 255, 255),
            -0.025em 0.05em 0 rgb(2, 92, 241);
    }
    14% {
        text-shadow: 0.05em 0 0 rgb(5, 240, 253), -0.05em -0.025em 0 rgba(255, 255, 255),
            -0.025em 0.05em 0 rgb(2, 92, 241);
    }
    15% {
        text-shadow: -0.05em -0.025em 0 rgb(5, 240, 253), 0.025em 0.025em 0 rgba(255, 255, 255),
            -0.05em -0.05em 0 rgb(2, 92, 241);
    }
    49% {
        text-shadow: -0.05em -0.025em 0 rgb(5, 240, 253), 0.025em 0.025em 0 rgba(255, 255, 255),
            -0.05em -0.05em 0 rgb(2, 92, 241);
    }
    50% {
        text-shadow: 0.025em 0.05em 0 rgb(5, 240, 253), 0.05em 0 0 rgba(255, 255, 255), 0 -0.05em 0 rgb(2, 92, 241);
    }
    99% {
        text-shadow: 0.025em 0.05em 0 rgb(5, 240, 253), 0.05em 0 0 rgba(255, 255, 255), 0 -0.05em 0 rgb(2, 92, 241);
    }
    100% {
        text-shadow: -0.025em 0 0 rgb(5, 240, 253), -0.025em -0.025em 0 rgba(255, 255, 255),
            -0.025em -0.05em 0 rgb(2, 92, 241);
    }
}

.buttonBorder:active .glitch span:first-child {
    animation: glitch 650ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
    transform: translate(-0.025em, -0.16em);
    opacity: 0.8;
}

.buttonBorder:active .glitch span:last-child {
    animation: glitch 375ms infinite;
    clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
    transform: translate(0.1125em, 0.16em);
    opacity: 0.8;
}

.buttonBorder {
    @extend %flex-center;
    position: relative;
    overflow: hidden;
    width: var(--width);
    height: 65px;
    &::after {
        background: var(--color-after);
    }
    &::before {
        background: var(--color-before);
    }
}

.buttonBorder:hover::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 1;
    height: 100%;
    width: calc(100% + 2px);
    animation: borderTop 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes borderTop {
    0% {
        height: 5px;
        width: 0;
    }
    70% {
        height: 5px;
        width: calc(100% + 2px);
    }
    100% {
        height: 100%;
    }
}

.buttonBorder:hover::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    box-shadow: 0px 1px 81px 2px rgba(255, 255, 255, 0.56);
    width: calc(100% + 2px);
    animation: borderBottom 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes borderBottom {
    0% {
        height: 5px;
        width: 0;
    }
    70% {
        height: 5px;
        width: calc(100% + 2px);
    }
    100% {
        height: 100%;
    }
}

.buttonBorder:active::before {
    width: 0;
    height: 0;
    z-index: 2;
    border-style: solid;
    border-width: 15px 0 5px 180px;
    border-color: transparent transparent transparent #ffffff6b;
    left: 0;
    top: 50%;
}

@media (max-width: 480px) {
    .button_container.footer {
        transform: scaleX(1.75);
    }
    .button_text.footer {
        transform: scaleX(0.65);
        top: 18px;
        left: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
    }
    .inInput {
        margin: 0;
    }
}

@media (max-width: 320px) {
    .button_container.footer {
        transform: scaleX(1.6);
    }
    .button_text.footer {
        transform: scaleX(0.65);
        top: 18px;
        left: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
    }
    .inInput {
        margin: 0;
    }
}
