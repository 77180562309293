$dark-color-main: #ffffff;
$dark-color-second: #d5d5d5;
$dark-color-third: #3b3943;
$dark-color-fourth: #f6f8fc;
$dark-color-blue1: linear-gradient(91.11deg, #025cf1 -3.82%, #05f0fd 66.71%, #7d48e4 179.57%);
$dark-color-blue2: linear-gradient(90.31deg, rgba(6, 240, 254, 0.94) 6.26%, rgba(2, 92, 241, 0.94) 118.81%);
$dark-background: #12101a;

$light-color-main: #202124;
$light-color-second: #4b576c;
$light-color-third: #7e8a9f;
$light-color-fourth: #c1c9d9;
$light-color-blue: #0f73ef;
$light-background: linear-gradient(123.43deg, #f2f4f8 0.55%, #ffffff 100%);

$input-background: #1a1922;
$input-error-dark: #ed1d2d;
$input-error-dark1: #590209;
$input-error-dark2: #472c04;
$input-error-light: #f29206;
$input-error-light1: #ff4a4af0;
