.R {
    top: -40px;
    right: -200px;

    &.loading {
        animation-duration: 6s;
        animation-name: R_Loading;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.error {
        animation-duration: 6s;
        animation-name: R_Error;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.waiting {
        animation-duration: 6s;
        animation-name: R_Waiting;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.disappear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: R_Disappear;
        animation-timing-function: cubic-bezier(0.92, 0.04, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.appear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: R_Appear;
        animation-timing-function: cubic-bezier(0.61, 0.02, 0.89, 0.91);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.grab {
        animation-duration: 1s;
        animation-name: R_Grab;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        // animation-iteration-count: infinite;
    }
    &.success {
        animation-duration: 3s;
        animation-name: R_Success;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.afterWaiting {
        &.appear {
            animation: R_Appear 1.5s forwards, R_Waiting 6s 2.2s infinite;
        }
    }
    &.afterError {
        transform: translate(-35px, 350px) rotate(-210deg);

        &.loading {
            animation: R_Restore 3s forwards, R_Loading 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: R_Restore 3s forwards, R_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: R_DisappearAfterError;
            overflow: hidden;
        }
        &.appear {
            animation-name: R_AppearToError;
            overflow: hidden;
        }
    }
    &.afterSuccess {
        transform: translate(-129px, -12px) rotate(120deg);

        &.loading {
            animation: R_Destroy 3s forwards, R_Loading 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: R_Destroy 3s forwards, R_Waiting 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: R_DisappearAfterSuccess;
        }
        &.appear {
            animation-name: R_AppearAfterSuccess;
        }
    }
}
@keyframes R_Loading {
    0% {
        transform: translate(0px, 0px);
    }
    25% {
        transform: translate(-30px, 0px);
    }
    50% {
        transform: translate(0px, 0px);
    }
    75% {
        transform: translate(-30px, 0px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}
@keyframes R_Error {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    14% {
        transform: translate(0px, 350px) rotate(-105deg);
    }
    14.2% {
        transform: translate(-2px, 350px) rotate(-115deg);
    }
    16% {
        transform: translate(-5px, 350px) rotate(-145deg);
    }

    35%,
    100% {
        transform: translate(-35px, 350px) rotate(-210deg);
    }
}
@keyframes R_Restore {
    0% {
        transform: translate(-35px, 350px) rotate(-210deg);
    }
    19% {
        transform: translate(-5px, 350px) rotate(-145deg);
    }
    20.8% {
        transform: translate(-2px, 350px) rotate(-115deg);
    }
    21% {
        transform: translate(0px, 350px) rotate(-105deg);
    }
    36%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes R_Waiting {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 30px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes R_Success {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    35% {
        transform: translate(50px, 0) rotate(0deg);
    }
    50%,
    100% {
        transform: translate(-129px, -12px) rotate(120deg);
        overflow: inherit;
    }
}
@keyframes R_Destroy {
    0%,
    10% {
        transform: translate(-129px, -12px) rotate(120deg);
    }
    20% {
        transform: translate(50px, 0) rotate(0deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes R_Disappear {
    0% {
        width: 69px;
        transform: translate(0, 0);
    }
    30% {
        width: 69px;
    }
    55%,
    100% {
        width: 0px;
        transform: translate(40px, 0);
    }
}
@keyframes R_DisappearAfterSuccess {
    0% {
        width: 69px;
        transform: translate(-129px, -12px) rotate(120deg);
    }

    41% {
        width: 69px;
    }
    50% {
        transform: translate(20px, 0) rotate(0deg);
    }
    55%,
    100% {
        transform: translate(40px, 0) rotate(0deg);
        width: 0px;
    }
}
@keyframes R_Appear {
    0%,
    86% {
        width: 0px;
        transform: translate(40px, 0);
        overflow: hidden;
    }
    90%,
    100% {
        width: 69px;
        transform: translate(0, 0);
    }
}
@keyframes R_AppearToError {
    0%,
    86% {
        width: 0px;
        transform: translate(40px, 0);
        overflow: hidden;
    }
    90%,
    100% {
        width: 69px;
        transform: translate(-35px, 350px) rotate(-210deg);
    }
}
@keyframes R_AppearAfterSuccess {
    0%,
    76% {
        width: 0px;
        transform: translate(40px, 0);
        overflow: hidden;
    }
    90%,
    100% {
        width: 69px;
        transform: translate(-129px, -12px) rotate(120deg);
        overflow: inherit;
    }
}
@keyframes R_DisappearAfterError {
    0%,
    30% {
        transform: translate(-35px, 350px) rotate(-210deg);
    }

    70% {
        width: 69px;
    }

    85%,
    100% {
        width: 0px;
        transform: translate(40px, 0);
    }
}
@keyframes R_Grab {
    0%,
    20% {
        transform: translate(0, 0);
    }
    40% {
        transform: translate(-130px, 20px);
    }
    60% {
        transform: translate(-120px, 175px);
    }
    70% {
        transform: translate(-120px, 205px);
    }
    76%,
    100% {
        transform: translate(-130px, 175px);
    }
}
