.map {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 596.51px;
    margin: 0 auto;
    background-image: url('../../../../assets/img/map.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.map.light {
    background-image: url('../../../../assets/img/mapLight.svg');
}

.white.light .circle g circle {
    fill: #f2bf65;
}
.white.light .circle circle {
    stroke: #f2bf65;
}
.white.light .circle g ellipse {
    fill: #f2bf65;
}
.white.light .circle ellipse {
    stroke: #f2bf65;
}

.blue.light .circle g circle {
    fill: #0f73ef;
}
.blue.light .circle circle {
    stroke: #0f73ef;
}
.locator {
    position: absolute;
    width: 110px;
    height: 110px;
}

.circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.locator.blue .circle:nth-child(1) {
    animation: locatorBlue 3s ease-in-out infinite;
}

.locator.blue .circle:nth-child(2) {
    animation: locatorBlue 3s ease-in-out 3s infinite;
}

@keyframes locatorBlue {
    from {
        /* opacity: 1; */
        transform: translate(-50%, -50%) scale(0.5);
    }
    to {
        /* opacity: 0.5; */
        transform: translate(-50%, -50%) scale(1);
    }
}

.locator.white .circle:nth-child(1) {
    animation: locatorWhite 3s ease-in-out infinite;
}

.locator.white .circle:nth-child(2) {
    animation: locatorWhite 3s ease-in-out 3s infinite;
}

@keyframes locatorWhite {
    from {
        /* opacity: 1; */
        transform: translate(-50%, -50%) scale(0.5);
    }
    to {
        /* opacity: 0.2; */
        transform: translate(-50%, -50%) scale(1);
    }
}

@media (max-width: 1280px) {
    .map {
        height: 596.51px;
    }
    .locator {
        transform: scale(0.8);
    }
}

@media (max-width: 1024px) {
    .map {
        height: 447px;
    }
    .locator {
        transform: scale(0.7);
    }
}

@media (max-width: 820px) {
    .map {
        height: 370px;
    }
    .locator {
        transform: scale(0.6);
    }
}

@media (max-width: 600px) {
    .map {
        height: 300px;
    }
    .locator {
        transform: scale(0.5);
    }
}

@media (max-width: 480px) {
    .map {
        height: 209px;
    }
    .locator {
        transform: scale(0.3);
    }
}
