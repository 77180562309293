.LT {
    top: -100px;
    left: -100px;

    &.loading {
        animation-duration: 6s;
        animation-name: LT_Loading;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }

    &.error {
        animation-duration: 6s;
        animation-name: LT_Error;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.waiting {
        animation-duration: 6s;
        animation-name: LT_Waiting;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.disappear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: LT_Disappear;
        animation-timing-function: cubic-bezier(0.92, 0.04, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.appear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: LT_Appear;
        animation-timing-function: cubic-bezier(0, 0.15, 1, 1);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.grab {
        animation-duration: 1s;
        animation-name: LT_Grab;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        // animation-iteration-count: infinite;
    }
    &.success {
        animation-duration: 3s;
        animation-name: LT_Success;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.afterWaiting {
        &.appear {
            animation: LT_Appear 1.5s forwards, LT_Waiting 6s 2.2s infinite;
        }
    }
    &.afterError {
        transform: translate(19px, 116px) rotate(120deg);

        &.loading {
            animation: LT_Restore 3s forwards, LT_Loading 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: LT_Restore 3s forwards, LT_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: LT_DisappearAfterError;
            overflow: hidden;
        }
        &.appear {
            animation-name: LT_AppearToError;
            overflow: hidden;
        }
    }
    &.afterSuccess {
        transform: translate(19px, 117px) rotate(120deg);
        overflow: hidden;

        &.loading {
            animation: LT_Destroy 3s forwards, LT_Loading 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: LT_Destroy 3s forwards, LT_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: LT_DisappearAfterSuccess;
        }
        &.appear {
            animation-name: LT_AppearAfterSuccess;
        }
    }
}

@keyframes LT_Loading {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }

    25% {
        transform: translate(25px, 125px) rotate(-90deg);
    }
    50% {
        transform: translate(160px, 145px) rotate(0);
    }
    75% {
        transform: translate(125px, 25px) rotate(90deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes LT_Error {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15% {
        transform: translate(0px, 400px) rotate(-135deg);
    }
    18% {
        transform: translate(15px, 405px) rotate(-75deg);
    }
    35%,
    100% {
        transform: translate(21px, 408px) rotate(-70deg);
    }
}
@keyframes LT_Restore {
    0% {
        transform: translate(21px, 408px) rotate(-70deg);
    }
    17% {
        transform: translate(15px, 405px) rotate(-75deg);
    }
    20% {
        transform: translate(0px, 400px) rotate(-135deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes LT_Waiting {
    0% {
        transform: translate(0, 0);
    }
    60% {
        transform: translate(0, 40px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes LT_Success {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    40% {
        transform: translate(-100px, 0) rotate(0deg);
    }
    55%,
    100% {
        overflow: inherit;
        transform: translate(19px, 117px) rotate(120deg);
    }
}

@keyframes LT_Destroy {
    0% {
        transform: translate(19px, 117px) rotate(120deg);
    }
    10%,
    25% {
        transform: translate(-100px, 0) rotate(0deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes LT_Disappear {
    0% {
        width: 43px;
        transform: translate(0, 0);
    }
    80% {
        width: 43px;
    }
    85%,
    100% {
        width: 0px;
        transform: translate(330px, 50px);
    }
}
@keyframes LT_DisappearAfterSuccess {
    0% {
        width: 43px;
        transform: translate(19px, 117px) rotate(120deg);
    }
    80% {
        width: 43px;
    }
    85%,
    100% {
        width: 0px;
        transform: translate(330px, 50px) rotate(0deg);
    }
}

@keyframes LT_Appear {
    0%,
    63% {
        width: 0;
        transform: translate(330px, 50px);
        overflow: hidden;
    }
    65% {
        width: 43px;
    }
    90%,
    100% {
        width: 43px;
        transform: translate(0, 0);
        overflow: inherit;
    }
}
@keyframes LT_AppearToError {
    0%,
    63% {
        width: 0;
        transform: translate(330px, 50px);
        overflow: hidden;
    }
    65% {
        width: 43px;
    }
    90%,
    100% {
        width: 43px;
        transform: translate(21px, 408px) rotate(-70deg);
    }
}
@keyframes LT_AppearAfterSuccess {
    0%,
    63% {
        width: 0;
        transform: translate(330px, 50px);
        overflow: hidden;
    }
    65% {
        width: 43px;
    }
    80%,
    100% {
        width: 43px;
        transform: translate(21px, 408px) rotate(-70deg);
    }
}
@keyframes LT_DisappearAfterError {
    0% {
        transform: translate(21px, 408px) rotate(-70deg);
    }
    50% {
        width: 43px;
    }
    65%,
    100% {
        width: 0px;
        transform: translate(330px, 50px);
    }
}
@keyframes LT_Grab {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(-10px, 72px);
    }
    40% {
        transform: translate(50px, 232px);
    }
    60%,
    100% {
        transform: translate(40px, 382px);
    }
}
