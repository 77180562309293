@import './scss';
.setup {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background-color: #12101a;
    height: 0;
    width: 100%;
    animation-duration: 0.5s;
    animation-name: initSetup;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-delay: 0.9s;
}
.setup.light {
    .closeButton {
        & path {
            stroke: #202124;
        }
    }
    .arrowLeft,
    .arrowRight {
        & path {
            fill: #0f73ef;
        }
    }

    .step {
        position: relative;
        cursor: pointer;
        rect {
            stroke: #202124;
        }
        &.active rect {
            stroke: #0f73ef;
        }
        &.read {
            &.active {
                & rect {
                    animation: readAndActiveStrokeLight 0.2s ease-in-out 1 forwards;
                }
            }
        }
    }
}

.setupPage {
    position: fixed;
    top: 0;
    height: 0;
    overflow-x: hidden;
    z-index: 1002;
    width: 100%;
    height: 0;
    animation-duration: 0.5s;
    animation-name: showSetup;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-delay: 1.9s;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: linear-gradient(123.43deg, #f2f4f8 0.55%, #ffffff 100%);
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, rgba(6, 240, 254, 0.94) 6.26%, rgba(2, 92, 241, 0.94) 118.81%);
        border-radius: 50px;
    }
}

.numberOfStepsBoxes {
    display: flex;
    justify-content: center;
    margin: 44px 0;
    & input {
        cursor: pointer;
    }
    & input:not(:nth-child(5)) {
        margin: 0 10px 0 0;
    }
}
.step {
    position: relative;
    cursor: pointer;
    &.active rect {
        stroke: url(#paint0_linear_1377_117);
    }
    &.active {
        animation: readAndActiveContainer 0.7s ease-in-out 1 forwards;
        & rect {
            animation: readAndActiveStroke 0.2s ease-in-out 1 forwards;
        }
    }
    &.read {
        animation: readRotate 1s ease-in-out 1 forwards;
        & rect {
            animation: readSuccess 0.2s ease-in-out 1 forwards;
        }
    }
    &:not(:last-of-type) {
        margin-right: 17px;
    }
}

@keyframes readRotate {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(0.5) rotate(360deg);
    }
    100% {
        transform: scale(1) rotate(-360deg);
    }
}

@keyframes readSuccess {
    100% {
        stroke: url(#paint1_linear_1377_117);
    }
}
@keyframes readAndActiveStroke {
    100% {
        stroke: url(#paint0_linear_1377_117);
    }
}
@keyframes readAndActiveStrokeLight {
    100% {
        stroke: #0f73ef;
    }
}

@keyframes readAndActiveContainer {
    0% {
        transform: scale(0);
    }
    25% {
        top: -50px;
    }

    100% {
        transform: scale(1);
        top: 0;
    }
}
.steps {
    display: flex;
    justify-content: center;
}
.stepButtonsBoxesImage {
    background-image: url('../../assets/img/stepButtonsBoxesImage.svg');
    background-position: center;
    width: 67.36px;
    height: 70px;
    margin: 50px auto 20px auto;
}
.onlyNextButton {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 325px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 650px;
}
.stepButtons {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 325px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 650px;
}
.arrowStepsButton {
    cursor: pointer;
    user-select: none;
    width: 130.5px;
    height: 64px;
    font-size: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @extend %flex-center;
}
.arrowRightSide {
    background-image: url('../../assets/img/arrowRightSideButtonImage.svg');
}
.arrowLeftSide {
    background-image: url('../../assets/img/arrowLeftSideButtonImage.svg');
}

@keyframes initSetup {
    0% {
        z-index: 10000;
        height: 0;
    }
    100% {
        z-index: 1000;
        height: 100vh;
    }
}
@keyframes showSetup {
    0% {
        height: 0;
    }
    100% {
        height: 100vh;
    }
}

.closeButton {
    cursor: pointer;
    position: absolute;
    right: 50px;
    top: 44px;
}
.fixedButtonsMobile {
    display: none;
}

.arrowLeft {
    cursor: pointer;
}

.arrowRight {
    cursor: pointer;
    transform: rotate(180deg);
}
.stepBoxesMobile {
    background-image: url(../../assets/img/smallBoxesMobile.svg);
    width: 31px;
    height: 33px;
}

.arrowLeftDesktop,
.arrowRightDesktop {
    cursor: pointer;
    opacity: 0;
    width: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    transition: all ease-in-out 0.5s;
    & svg {
        position: absolute;
        left: 50%;
        top: 50%;
    }
    &:hover {
        opacity: 1;
    }
}
.arrowLeftDesktop {
    left: 0;
    background: linear-gradient(90deg, rgba(15, 116, 239, 0.19), rgba(254, 254, 254, 0));
    & svg {
        transform: translate(-50%, -50%) rotate(180deg);
    }
}
.arrowRightDesktop {
    right: 0;
    background: linear-gradient(90deg, rgba(254, 254, 254, 0), rgba(15, 116, 239, 0.19));
    & svg {
        transform: translate(-50%, -50%);
    }
}
@media (max-width: 1150px) {
    .arrowLeftDesktop,
    .arrowRightDesktop {
        width: 100px;
    }
}
@media (max-width: 980px) {
    .arrowLeftDesktop,
    .arrowRightDesktop {
        width: 50px;
    }
}

@media (max-width: 830px) {
    .closeButton {
        right: 20px;
        top: 20px;
        width: 12.5px;
        height: 12.5px;
    }
    .numberOfStepsBoxes {
        margin: 20px 0;
    }
}

@media (max-width: 720px) {
    .setup {
        overflow-y: auto;
    }
    .arrowStepsButton {
        width: 115.5px;
        height: 54px;
        font-size: 14px;
        background-size: contain;
    }
    .onlyNextButton {
        margin: 35px 0;
        gap: 355px;
    }
    .stepButtons {
        margin: 35px 0;
        gap: 120px;
    }
    .fixedButtonsDesktop {
        display: none;
    }
    .fixedButtonsMobile {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 50px;
        background: linear-gradient(96.82deg, rgba(255, 255, 255, 0.06) 17.79%, rgba(255, 255, 255, 0.025) 105.61%);
        box-shadow: 0px 3.08036px 18.4822px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(30.8036px);
        position: fixed;
        bottom: 0;
    }
    .stepButtonsBoxesImage {
        display: none;
    }
}
.canvas {
    position: fixed;
    top: 0;
    height: 0;
    overflow-x: hidden;
    z-index: 1001;
    width: 100%;
    height: 0;
    animation-duration: 0.5s;
    animation-name: showSetup;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-delay: 1.9s;
}
