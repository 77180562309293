.RB {
    bottom: -100px;
    right: -100px;

    &.loading {
        animation-duration: 6s;
        animation-name: RB_Loading;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    &.error {
        animation-duration: 6s;
        animation-name: RB_Error;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.waiting {
        animation-duration: 6s;
        animation-name: RB_Waiting;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.disappear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: RB_Disappear;
        animation-timing-function: cubic-bezier(0.92, 0.04, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.appear {
        animation-duration: 1.5s;
        animation-name: RB_Appear;
        animation-timing-function: cubic-bezier(0.61, 0.02, 0.89, 0.91);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.grab {
        animation-duration: 1s;
        animation-name: RB_Grab;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        // animation-iteration-count: infinite;
    }
    &.success {
        animation-duration: 3s;
        animation-name: RB_Success;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.afterWaiting {
        &.appear {
            animation: RB_Appear 1.5s forwards, RB_Waiting 6s 2.2s infinite;
        }
    }
    &.afterError {
        transform: translate(-24px, -29px) rotate(0deg);

        &.loading {
            animation: RB_Restore 3s forwards, RB_Loading 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: RB_Restore 3s forwards, RB_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: RB_DisappearAfterError;
            overflow: hidden;
        }
        &.appear {
            animation-name: RB_AppearToError;
            overflow: hidden;
        }
    }
    &.afterSuccess {
        transform: translate(-19px, -27px) rotate(0deg);
        overflow: hidden;

        &.loading {
            animation: RB_Destroy 3s forwards, RB_Loading 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: RB_Destroy 3s forwards, RB_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: RB_DisappearAfterSuccess;
        }
        &.appear {
            animation-name: RB_AppearAfterSuccess;
        }
    }
}
@keyframes RB_Loading {
    0% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(-25px, -125px) rotate(-90deg);
    }
    50% {
        transform: translate(-160px, -145px) rotate(0);
    }
    75% {
        transform: translate(-125px, -25px) rotate(90deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes RB_Error {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    100% {
        transform: translate(0px, 300px) rotate(-135deg);
    }
}
@keyframes RB_Restore {
    0%,
    20% {
        transform: translate(0px, 300px) rotate(-135deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes RB_Waiting {
    0% {
        transform: translate(0, 0);
    }
    60% {
        transform: translate(0, 40px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes RB_Success {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    40% {
        transform: translate(100px, 0) rotate(0deg);
    }
    55%,
    100% {
        transform: translate(-19px, -27px) rotate(0deg);
        overflow: inherit;
    }
}
@keyframes RB_Destroy {
    0% {
        transform: translate(-19px, -27px) rotate(0deg);
    }
    10%,
    25% {
        transform: translate(100px, 0) rotate(0deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes RB_Disappear {
    0% {
        width: 43px;
        transform: translate(0, 0);
    }
    62% {
        width: 43px;
    }
    65%,
    100% {
        width: 0px;
        transform: translate(130px, -40px);
    }
}
@keyframes RB_DisappearAfterSuccess {
    0% {
        width: 43px;
        transform: translate(-19px, -27px) rotate(0deg);
    }
    62% {
        width: 43px;
    }
    65%,
    100% {
        transform: translate(130px, -40px) rotate(0deg);
        width: 0px;
    }
}

@keyframes RB_Appear {
    0%,
    81% {
        width: 0px;
        transform: translate(130px, -40px);
        overflow: hidden;
    }
    84% {
        width: 43px;
    }
    90%,
    100% {
        width: 43px;
        transform: translate(0, 0);
        overflow: inherit;
    }
}
@keyframes RB_AppearToError {
    0%,
    81% {
        width: 0px;
        transform: translate(130px, -40px);
        overflow: hidden;
    }
    84% {
        width: 43px;
    }
    90%,
    100% {
        width: 43px;
        transform: translate(0px, 300px) rotate(-135deg);
    }
}
@keyframes RB_AppearAfterSuccess {
    0%,
    71% {
        width: 0px;
        transform: translate(130px, -40px);
        overflow: hidden;
    }
    74% {
        width: 43px;
    }
    95%,
    100% {
        width: 43px;
        transform: translate(-19px, -27px) rotate(0deg);
        overflow: inherit;
    }
}
@keyframes RB_DisappearAfterError {
    0%,
    50% {
        transform: translate(0px, 300px) rotate(-135deg);
    }
    55% {
        width: 43px;
    }
    70%,
    100% {
        width: 0px;
        transform: translate(130px, -40px);
    }
}
@keyframes RB_Grab {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(50px, 65px);
    }
    40% {
        transform: translate(30px, 85px);
    }
    60% {
        transform: translate(80px, 235px);
    }
    80%,
    100% {
        transform: translate(50px, 235px);
    }
}
