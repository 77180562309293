.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
}

.wrapper.light {
    .specialTitle {
        color: #0f73ef;
    }
    .boxWrapper {
        background: linear-gradient(90deg, rgb(15, 115, 239) 0%, rgb(255, 255, 255) 50%, rgb(15, 115, 239) 100%);
        padding: 1.5px;
    }
    .boxContent {
        color: #1e1d1f;
        background: white;
    }
    .copyLinkIcon {
        width: 30px;
        height: 30px;
        background-position: center;
        background-image: url('../../../../../assets/img/copyLinkIconLight.svg');
    }
}

.title {
    font-size: 28px;
    font-weight: 700;
    line-height: 42.84px;
    text-align: center;
}

.specialTitle {
    width: 1015px;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;

    color: white;
    display: flex;
    justify-content: center;
}

.boxWithTitle {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.boxWrapper {
    width: 1009px;
    height: 64px;
    position: relative;
    background: linear-gradient(50deg, #06f0fe 20%, #025cf1 70%);
    padding: 3px;
}

.boxContent {
    height: 100%;
    box-sizing: border-box;
    background: #1e1d1f;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
}

.boxContent p {
    font-size: 18px;
    font-weight: 400;
    line-height: 186%;
}

.copyLinkIcon {
    width: 30px;
    height: 30px;
    background-position: center;
    background-image: url('../../../../../assets/icons/copyLinkIcon.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

@media (max-width: 1250px) {
    .boxWithTitle {
        width: 100%;
    }
    .boxWrapper {
        width: 90%;
        height: auto;
        margin: 0 auto;
    }
    .boxContent {
        width: 100%;
        padding: 16px 18px 15px 16px;
    }
    .specialTitle {
        width: 90%;
    }
}
@media (max-width: 1190px) {
    .boxWrapper {
        width: 700px;
    }
    .specialTitle {
        width: 700px;
    }
}
@media (max-width: 820px) {
    .boxContent p {
        font-size: 14px;
    }
    .title {
        font-size: 24px;
        line-height: 18px;
        text-align: left;
        margin-left: 40px;
    }
    .specialTitle {
        font-size: 26px;
        line-height: 150%;
        margin: 0 auto;

        width: 90%;
    }
    .boxWithTitle {
        width: 100%;
    }
    .boxWrapper {
        width: 90%;
        height: auto;
        margin: 0 auto;
    }
    .boxContent {
        width: 100%;
        padding: 16px 18px 15px 16px;
    }
}

@media (max-width: 600px) {
    .boxContent p {
        font-size: 10px;
        line-height: 186%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        width: 290px;
    }
    .title {
        font-size: 14px;
        line-height: 18px;
        padding: 0 0 0 20px;
        text-align: left;
    }
    .specialTitle {
        font-size: 16px;
        line-height: 150%;
    }
}

@media (max-width: 420px) {
    .boxContent p {
        width: 220px;
    }
    .title {
        padding: 0 0 0 12px;
    }
    .copyLinkIcon {
        width: 16px;
        height: 16px;
    }
}
