@import './scss';
.app {
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    margin: 0;
    box-sizing: border-box;
    @include page-theme($dark-color-main, $dark-background);
}

.app.light {
    @include page-theme($light-color-main, $light-background);
}
.app.light .button {
    background: #007bff;
}
.app.light .button::after {
    border-color: transparent #007bff transparent transparent;
}
.app.light .button::before {
    border-color: transparent transparent transparent #007bff;
}

.button {
    @extend %flex-center;
    position: fixed;
    bottom: 0px;
    left: calc(50% - 50px);
    z-index: 10;
    width: 100px;
    height: 30px;
    cursor: pointer;
    color: white;
    background: linear-gradient(90.31deg, rgba(6, 240, 254, 0.94) 6.26%, rgba(2, 92, 241, 0.94) 118.81%);
}

.button::after {
    content: '';
    position: absolute;
    left: -40px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 40px 0 0;
    border-color: transparent rgba(6, 240, 254, 0.94) transparent transparent;
}

.button::before {
    content: '';
    position: absolute;
    right: -40px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 0 0 30px;
    border-color: transparent transparent transparent rgba(37, 116, 244, 0.94);
    border-width: 30px 0 0 40px;
}

body::-webkit-scrollbar {
    width: 4px;
}

body::-webkit-scrollbar-track {
    background: linear-gradient(123.43deg, #f2f4f8 0.55%, #ffffff 100%);
}

body::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(6, 240, 254, 0.94) 6.26%, rgba(2, 92, 241, 0.94) 118.81%);
    border-radius: 50px;
}
