.logoContainer {
    width: 40px;
    height: 55px;
    position: relative;
    top: -5px;
    .top {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        animation: levitationTop 1.5s ease-in-out infinite alternate-reverse;
    }
    .middle {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 36.5px;
        display: flex;
        justify-content: space-around;
        width: 38px;
        animation: levitationMiddle 1.5s ease-in-out 0.1s infinite alternate-reverse;
    }
    .bottom {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        animation: levitationBottom 1.5s ease-in-out 0.2s infinite alternate-reverse;
    }
}

@keyframes levitationTop {
    0% {
        top: 10px;
    }
    100% {
        top: 0;
    }
}

@keyframes levitationMiddle {
    0% {
        top: 46.5px;
    }
    100% {
        top: 39px;
    }
}

@keyframes levitationBottom {
    0% {
        bottom: -10px;
    }
    100% {
        bottom: -5px;
    }
}
