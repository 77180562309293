.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding-bottom: 50px;
}

.wrapper.light {
    .specialTitle {
        color: #0f73ef;
    }
    .optionPoint {
        background-image: url('../../../../assets/img/tableListPointLight.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .rightBorderBg path {
        stroke: #0f73ef;
    }
    .leftBorderBg path {
        stroke: #0f73ef;
    }
}
.title {
    font-size: 34px;
    font-weight: 700;
    line-height: 42.84px;
}
.blockWrapper > h2.title {
    font-size: 28px;
}
.specialTitle {
    background: linear-gradient(90.31deg, rgba(6, 240, 254, 0.94) 6.26%, rgba(2, 92, 241, 0.94) 188.81%);
    background-clip: text;
    color: transparent;
}

.blockWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 62px;
}

.optionsList {
    width: 452px;
    height: 501px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background: linear-gradient(96.82deg, rgba(255, 255, 255, 0.06) 17.79%, rgba(255, 255, 255, 0.025) 105.61%);
    box-shadow: 0px 2.9298px 17.5788px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(62px);
}

.optionWrapper {
    width: 351px;
    height: 26px;
    display: flex;
    align-items: center;
    gap: 18px;
    margin-left: 20px;
}

.optionPoint {
    width: 18px;
    height: 18px;
    background-image: url('../../../../assets/img/tableListPoint.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.optionText {
    font-size: 14px;
    font-weight: 600;
    line-height: 26.04px;
}
.rightBorderBg {
    position: absolute;
    right: -23px;
    top: 67px;
    width: 246px;
    height: 185px;
}
.leftBorderBg {
    position: absolute;
    left: -23px;
    bottom: -23px;
    width: 246px;
    height: 185px;
}

@media (max-width: 1000px) {
    .optionWrapper {
        width: 98%;
    }
    .optionPoint {
        margin-left: 16px;
    }
}
@media (max-width: 720px) {
    .rightBorderBg,
    .leftBorderBg {
        display: none;
    }
    .wrapper {
        gap: 24px;
    }
    .blockWrapper {
        gap: 18px;
    }
    .optionsList {
        width: 100%;
        height: auto;
        background: none;
        box-shadow: none;
        backdrop-filter: none;
        gap: 8px;
    }
    .optionWrapper {
        border-top: 2px solid rgba(6, 240, 254, 0.94);
        width: 100%;
        height: 40px;
        backdrop-filter: blur(30.8036px);
        background: linear-gradient(96.82deg, rgba(255, 255, 255, 0.06) 17.79%, rgba(255, 255, 255, 0.025) 105.61%);
        box-shadow: 0px 3.08036px 18.4822px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
    }
}

@media (max-width: 460px) {
    .optionWrapper {
        width: 98%;
    }
    .optionPoint {
        margin-left: 16px;
    }
    .wrapper > h2.title {
        font-size: 18px;
        line-height: 23px;
    }

    .blockWrapper > h2.title {
        font-size: 16px;
        line-height: 20px;
    }
    .optionText {
        font-weight: 600;
        font-size: 10px;
        line-height: 186%;
    }
}
