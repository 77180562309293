.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    z-index: 205;
    padding: 0 108px;
    margin: auto;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
    position: fixed;
    left: 0;
    right: 0;
}

.logo {
    cursor: pointer;
    transform: scale(0.8);
}
.logo.light path {
    fill: #0f73ef;
}
.logo.fixed {
    z-index: 1;
    position: fixed;
    left: 25px;
}

.navMenuWrapper {
    display: flex;
    align-items: center;
    gap: 40px;
}

.navItem {
    cursor: pointer;
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.navItem:hover {
    color: #76aaff;
}

.mobileMenu {
    display: none;
}

.menuButton {
    display: none;
}

.menuButton.fixed {
    z-index: 1;
    position: fixed;
    right: 15px;
}

.mobileHeader {
    display: none;
    position: absolute;
    top: 0;
    background: #13111b;
    width: 100%;
    padding: 110px 0 44px 30px;
}
.mobileHeader.light {
    background: linear-gradient(123.43deg, #f2f4f8 0.55%, #ffffff 100%);
    color: #202124;
}

.mobileHeader.show {
    animation: showMenu 0.6s ease-in-out 1 forwards;
}

.mobileHeader.hide {
    animation: hideMenu 0.6s ease-in-out 1 forwards;
}

@keyframes showMenu {
    from {
        right: -400px;
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    to {
        right: -25px;
        opacity: 1;
    }
}

@keyframes hideMenu {
    from {
        right: -25px;
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    to {
        right: -400px;
        opacity: 0;
    }
}

.mobileHeader div {
    font-weight: 700;
    font-size: 32px;
}

.mobileMenuItem {
    cursor: pointer;
    margin: 0 0 32px 0;
}

.mobileMenuItem:nth-child(5) {
    margin: 0 0 100px 0;
}

@media (min-width: 768px) and (max-width: 1280px) {
    .headerWrapper {
        padding: 0 54px;
    }
}

@media (max-width: 767px) {
    .logo {
        transform: scale(0.6);
    }
    .headerWrapper {
        padding: 0 24px;
    }
    .mobileHeader {
        position: fixed;
        height: 100vh;
        display: block;
        right: -24px;
    }
    .navMenuWrapper {
        display: none;
    }
    .menuButton {
        display: block;
        width: 30px;
        height: 30px;
        background-color: transparent;
        outline: none;
        border-style: none;
        cursor: pointer;
        user-select: none;
    }
    .mobileMenu {
        display: block;
        width: 24px;
        height: 1.5px;
        background: transparent;
        cursor: pointer;
        position: relative;
        &::before {
            content: '';
            width: 20px;
            height: 1.5px;
            background: white;
            position: absolute;
            top: -6px;
            right: 0;
        }
        &::after {
            content: '';
            width: 16px;
            height: 1.5px;
            background: white;
            position: absolute;
            top: 6px;
            right: 0;
        }
    }
    .mobileMenu.light {
        background-color: transparent;
        &::before,
        &::after {
            background: #7e8a9f;
        }
        &.open {
            animation: openMenuLight 0.4s ease-in-out 1 forwards;
        }
        &.close {
            animation: closeMenuLight 0.7s ease-in-out 0.1s 1 forwards;
        }
    }

    .open.mobileMenu {
        animation: openMenu 0.4s ease-in-out 1 forwards;
        &::before {
            animation: openMenuBefore 1s ease-in-out 1 forwards;
        }
        &::after {
            animation: openMenuAfter 1s ease-in-out 1 forwards;
        }
    }

    @keyframes openMenu {
        from {
            background: #7e8a9f;
        }
        to {
            background: transparent;
        }
    }
    @keyframes openMenu {
        from {
            background: white;
        }
        to {
            background: transparent;
        }
    }
    @keyframes openMenuBefore {
        0% {
            right: 0;
        }
        25% {
            top: 0;
            left: 0;
            width: 24px;
        }
        100% {
            top: 0;
            left: 0;
            width: 24px;
            transform: rotate(45deg);
        }
    }
    @keyframes openMenuAfter {
        0% {
            right: 0;
        }
        25% {
            top: 0;
            left: 0;
            width: 24px;
        }
        100% {
            top: 0;
            left: 0;
            width: 24px;
            transform: rotate(-45deg);
        }
    }
    .close.mobileMenu {
        animation: closeMenu 0.7s ease-in-out 0.1s 1 forwards;
        &::before {
            animation: closeMenuBefore 1s ease-in-out 1 forwards;
        }
        &::after {
            animation: closeMenuAfter 1s ease-in-out 1 forwards;
        }
    }

    @keyframes closeMenu {
        from {
            background: transparent;
        }
        to {
            background: white;
        }
    }
    @keyframes closeMenuLight {
        from {
            background: transparent;
        }
        to {
            background: #7e8a9f;
        }
    }
    @keyframes closeMenuBefore {
        0% {
            top: 0;
            left: 0;
            width: 24px;
            transform: rotate(45deg);
        }
        30% {
            transform: rotate(0);
            top: 0;
            left: 0;
            width: 24px;
        }
        100% {
            top: -6px;
            right: 0;
            width: 20px;
        }
    }
    @keyframes closeMenuAfter {
        0% {
            top: 0;
            left: 0;
            width: 24px;
            transform: rotate(-45deg);
        }
        30% {
            transform: rotate(0);
            top: 0;
            left: 0;
            width: 24px;
        }
        100% {
            width: 16px;
            top: 6px;
            right: 0;
        }
    }
}

@keyframes toDark {
    from {
        left: 2px;
    }
    to {
        left: 33px;
    }
}
@keyframes toLight {
    from {
        left: 33px;
    }
    to {
        left: 2px;
    }
}
