@import './LT.scss';
@import './RB.scss';
@import './RT.scss';
@import './LB.scss';

.cube {
    position: absolute;
    .side {
        transition: 3s;
    }
    &.blue {
        .cubeY {
            fill: #2f3fd3;
        }
        .cubeX {
            fill: #bdebff;
        }
        .cubeZ {
            fill: #4298e8;
        }
    }
    &.red {
        .cubeY {
            fill: #d32f2f;
        }
        .cubeX {
            fill: #ffbdbd;
        }
        .cubeZ {
            fill: #dc5e5e;
        }
    }
    &.green {
        &::before {
            content: '';
            position: absolute;
            height: 30px;
            width: 30px;
            background: transparent;
            border-radius: 50%;
            border-right: transparent;
            opacity: 0.4;
            transform: translate(18px, 18px) rotate(25deg);
            animation-duration: 4s;
            animation-name: before;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-delay: 2.5s;
        }

        &::after {
            content: '';
            position: absolute;
            height: 30px;
            width: 30px;
            background: transparent;
            border-radius: 50%;
            border-right: transparent;
            opacity: 0.4;
            transform: translate(-25px, 18px) rotate(25deg);
            animation-duration: 4s;
            animation-name: after;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-delay: 2s;
        }
        .cubeY {
            fill: #2fd349;
        }
        .cubeX {
            fill: #c7ffbd;
        }
        .cubeZ {
            fill: #2fd349;
        }
    }

    @keyframes after {
        0% {
            height: 30px;
            width: 30px;
            border-right: 2px solid #2fd349;
            transform: translate(-25px, 18px) rotate(25deg);
            opacity: 0.4;
        }
        100% {
            height: 100px;
            width: 100px;
            opacity: 0;
            border-right: 2px solid #2fd349;
            transform: translate(0px, 23px) rotate(30deg);
        }
    }
    @keyframes before {
        0% {
            height: 30px;
            width: 30px;
            border-right: 2px solid #2fd349;
            transform: translate(18px, 18px) rotate(25deg);
            opacity: 0.4;
        }
        100% {
            height: 100px;
            width: 100px;
            opacity: 0;
            border-right: 2px solid #2fd349;
            transform: translate(43px, 23px) rotate(26deg);
        }
    }
}
