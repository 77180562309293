.B {
    bottom: -200px;
    left: -40px;

    &.loading {
        animation-duration: 6s;
        animation-name: B_Loading;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    &.error {
        animation-duration: 6s;
        animation-name: B_Error;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.waiting {
        animation-duration: 6s;
        animation-name: B_Waiting;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.disappear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: B_Disappear;
        animation-timing-function: cubic-bezier(0.92, 0.04, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.appear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: B_Appear;
        animation-timing-function: cubic-bezier(0.6, 0.24, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.grab {
        animation-duration: 1s;
        animation-name: B_Grab;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.success {
        z-index: 2;
        animation-duration: 3s;
        animation-name: B_Success;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;

        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    &.afterWaiting {
        &.appear {
            animation: B_Appear 1.5s forwards, B_Waiting 6s 2.2s infinite;
        }
    }
    &.afterError {
        transform: translate(7px, -48px) rotate(0deg);

        &.loading {
            animation: B_Restore 3s forwards, B_Loading 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: B_Restore 3s forwards, B_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: B_DisappearAfterError;
            overflow: hidden;
        }
        &.appear {
            animation-name: B_AppearToError;
            overflow: hidden;
        }
    }
    &.afterSuccess {
        transform: translate(7px, -48px) rotate(0deg);
        overflow: hidden;

        &.loading {
            animation: B_Destroy 3s forwards, B_Loading 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: B_Destroy 3s forwards, B_Waiting 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            z-index: 2;
            animation-name: B_DisappearAfterSuccess;
        }
        &.appear {
            z-index: 2;
            animation-name: B_AppearAfterSuccess;

            &::after {
                display: none;
            }
            &::before {
                display: none;
            }
        }
    }
}

@keyframes B_Loading {
    0% {
        transform: translate(0px, 0px);
    }
    25% {
        transform: translate(0px, -30px);
    }
    50% {
        transform: translate(0px, 0px);
    }
    75% {
        transform: translate(0px, -30px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}
@keyframes B_Error {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    100% {
        transform: translate(0px, 200px) rotate(-135deg);
    }
}
@keyframes B_Restore {
    0%,
    20% {
        transform: translate(0px, 200px) rotate(-135deg);
    }

    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes B_Waiting {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 30px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes B_Success {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    25% {
        transform: translate(100px, 0) rotate(0deg);
    }
    30%,
    100% {
        transform: translate(7px, -48px) rotate(0deg);
        overflow: inherit;
    }
}
@keyframes B_Destroy {
    0%,
    15% {
        transform: translate(7px, -48px) rotate(0deg);
    }
    20% {
        transform: translate(100px, 0) rotate(0deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes B_Disappear {
    0% {
        width: 69px;
        transform: translate(0, 0);
    }
    75% {
        width: 69px;
    }
    80%,
    100% {
        width: 0px;
        transform: translate(240px, -120px);
    }
}
@keyframes B_DisappearAfterSuccess {
    0% {
        width: 69px;
        transform: translate(7px, -48px) rotate(0deg);
    }

    78% {
        width: 69px;
    }
    82%,
    100% {
        width: 0px;
        transform: translate(240px, -100px);
    }
}
@keyframes B_DisappearAfterError {
    0% {
        transform: translate(0px, 200px) rotate(-135deg);
    }

    78% {
        width: 69px;
    }
    90%,
    100% {
        width: 0px;
        transform: translate(240px, -100px);
    }
}
@keyframes B_Appear {
    0%,
    78% {
        width: 0px;
        overflow: hidden;
        transform: translate(240px, -120px);
    }
    80% {
        width: 69px;
    }
    90% {
        width: 69px;
        transform: translate(0, 0);
    }
}
@keyframes B_AppearToError {
    0%,
    68% {
        width: 0px;
        overflow: hidden;
        transform: translate(240px, -120px);
    }
    70% {
        width: 69px;
    }
    80%,
    100% {
        width: 69px;
        transform: translate(0px, 200px) rotate(-135deg);
    }
}
@keyframes B_AppearAfterSuccess {
    0%,
    40% {
        width: 0px;
        transform: translate(240px, -120px);
    }
    42% {
        width: 69px;
    }
    60% {
        width: 69px;
        transform: translate(7px, -48px) rotate(0deg);
        overflow: inherit;
    }
}
@keyframes B_Grab {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(93px, -160px);
    }
    40% {
        transform: translate(73px, -68px);
    }
    60% {
        transform: translate(103px, 82px);
    }
    70% {
        transform: translate(103px, 102px);
    }
    78%,
    100% {
        transform: translate(83px, 82px);
    }
}
