.LB {
    bottom: -100px;
    left: -100px;

    &.loading {
        animation-duration: 6s;
        animation-name: LB_Loading;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }

    &.error {
        animation-duration: 6s;
        animation-name: LB_Error;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.waiting {
        animation-duration: 6s;
        animation-name: LB_Waiting;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.disappear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: LB_Disappear;
        animation-timing-function: cubic-bezier(0.92, 0.04, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.appear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: LB_Appear;
        animation-timing-function: cubic-bezier(0, 0.15, 1, 1);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.grab {
        animation-duration: 1s;
        animation-name: LB_Grab;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.success {
        z-index: 2;
        animation-duration: 3s;
        animation-name: LB_Success;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    &.afterWaiting {
        &.appear {
            animation: LB_Appear 1.5s forwards, LB_Waiting 6s 2.2s infinite;
        }
    }
    &.afterError {
        transform: translate(0px, 300px) rotate(135deg);

        &.loading {
            animation: LB_Restore 3s forwards, LB_Loading 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: LB_Restore 3s forwards, LB_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: LB_DisappearAfterError;
            overflow: hidden;
        }
        &.appear {
            animation-name: LB_AppearToError;
            overflow: hidden;
        }
    }
    &.afterSuccess {
        transform: translate(80px, 0) rotate(0deg);

        &.loading {
            animation: LB_Destroy 3s forwards, LB_Loading 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: LB_Destroy 3s forwards, LB_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: LB_DisappearAfterSuccess;
        }
        &.appear {
            z-index: 2;
            animation-name: LB_AppearAfterSuccess;

            &::after {
                display: none;
            }
            &::before {
                display: none;
            }
        }
    }
}

@keyframes LB_Loading {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    25% {
        transform: translate(125px, -25px) rotate(-90deg);
    }
    50% {
        transform: translate(160px, -145px) rotate(0);
    }
    75% {
        transform: translate(25px, -125px) rotate(90deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes LB_Error {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    100% {
        transform: translate(0px, 300px) rotate(135deg);
    }
}
@keyframes LB_Restore {
    0%,
    20% {
        transform: translate(0px, 300px) rotate(135deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes LB_Waiting {
    0% {
        transform: translate(0, 0);
    }
    60% {
        transform: translate(0, 40px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes LB_Success {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    30% {
        transform: translate(-100px, 0) rotate(0deg);
    }
    35%,
    100% {
        overflow: inherit;
        transform: translate(80px, 0) rotate(0deg);
    }
}
@keyframes LB_Destroy {
    0%,
    10% {
        transform: translate(80px, 0) rotate(0deg);
    }
    25% {
        transform: translate(-100px, 0) rotate(0deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes LB_Disappear {
    0% {
        width: 43px;
        transform: translate(0, 0);
    }
    80% {
        width: 43px;
    }
    85%,
    100% {
        width: 0px;
        transform: translate(330px, -50px);
    }
}
@keyframes LB_DisappearAfterSuccess {
    0% {
        width: 43px;
        transform: translate(80px, 0) rotate(0deg);
    }
    80% {
        width: 43px;
    }
    85%,
    100% {
        width: 0px;
        transform: translate(330px, -50px);
    }
}
@keyframes LB_Appear {
    0%,
    63% {
        width: 0;
        transform: translate(330px, -50px);
        overflow: hidden;
    }
    65% {
        width: 43px;
    }
    90%,
    100% {
        width: 43px;
        transform: translate(0, 0);
        overflow: inherit;
    }
}
@keyframes LB_AppearToError {
    0%,
    63% {
        width: 0;
        transform: translate(330px, -50px);
        overflow: hidden;
    }
    65% {
        width: 43px;
    }
    90%,
    100% {
        width: 43px;
        transform: translate(0, 0);
        transform: translate(0px, 300px) rotate(135deg);
    }
}
@keyframes LB_AppearAfterSuccess {
    0%,
    53% {
        width: 0;
        transform: translate(330px, -50px);
        overflow: hidden;
    }
    55% {
        width: 43px;
    }
    75%,
    100% {
        width: 43px;
        transform: translate(80px, 0) rotate(0deg);
        overflow: inherit;
    }
}
@keyframes LB_DisappearAfterError {
    0%,
    28% {
        transform: translate(0px, 300px) rotate(135deg);
    }
    80% {
        width: 43px;
    }
    90%,
    100% {
        width: 0px;
        transform: translate(330px, -50px);
    }
}
@keyframes LB_Grab {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(-50px, 65px);
    }
    40% {
        transform: translate(-30px, 85px);
    }
    60% {
        transform: translate(-80px, 235px);
    }
    80%,
    100% {
        transform: translate(-50px, 235px);
    }
}
