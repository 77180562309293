@import './scss';
.container {
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

.title {
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 10px;
    user-select: none;
}
.container.light {
    & .size {
        color: $light-color-blue;
    }
}
.size {
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    line-height: 24px;
    color: $dark-color-second;
    margin-top: 0;
    margin-bottom: 10px;
    user-select: none;
}

.progress {
    display: flex;
    position: relative;
    width: 100%;
    height: 18px;
    cursor: pointer;
}

.bar {
    position: absolute;
    width: 2px;
    height: 18px;
    background-color: #5b5b5b;
}

.activeBart {
    background-color: #05f0fd;
}

.currentBar {
    background-color: #05f0fd;
    box-shadow: 0px 0px 7px 0px #05f0fd66;
    width: 8px;
}

.activeBart.light,
.currentBar.light {
    background-color: $light-color-blue;
}

.cursorPointer {
    background-image: url(../../assets/img/cursorPointer.svg);
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    top: 10px;
    left: 15%;
    animation: cursorAnimation 2s ease-in-out infinite;
}
@keyframes cursorAnimation {
    0% {
        opacity: 1;
        left: 12%;
    }
    100% {
        opacity: 0;
        left: 30%;
    }
}

@media (max-width: 320px) {
    .title,
    .size {
        font-size: 14px;
        line-height: 24px;
    }
}
