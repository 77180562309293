@import './scss';
.tabsContainer {
    width: 100%;
    gap: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 0 70px 0;
}

.tabsContainer.light {
    .tabButton {
        background-color: white;
    }
    .activeTab {
        color: #0f73ef;
    }
}
.title {
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;
}
.tabButtonsWrapper {
    display: flex;
    align-items: center;
    height: 59px;
    gap: 25px;
    text-transform: capitalize;
}
.tabButton {
    @extend %flex-center;
    cursor: pointer;
    width: 184px;
    height: 60px;
    user-select: none;
    position: relative;
    font-size: 24px;
    overflow: hidden;
}
.willBeSoon {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg, #05f0fd 0%, #025cf1 29%, #05f0fd 67%, #025cf1 100%);
    background-clip: border-box;
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textClip 3s linear infinite reverse;
    display: inline-block;
    font-size: 36px;
    margin-top: 50px;
}

@keyframes textClip {
    to {
        background-position: 200% center;
    }
}

.horizontal_faces::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.68px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
}
.tabButton:hover .horizontal_faces::before {
    animation: top 2s linear infinite;
}
.tabButton .active:nth-child(1)::before {
    background: linear-gradient(90deg, #025cf1, #05f0fd 60%);
    animation: none;
}
@keyframes top {
    0% {
        background: linear-gradient(90deg, transparent, #025cf1, #05f0fd);
        transform: translateX(-100%);
    }
    100% {
        background: linear-gradient(90deg, transparent, #025cf1, #05f0fd);
        transform: translateX(100%);
    }
}
.vertical_faces::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1.68px;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
}
.tabButton:hover .vertical_faces::before {
    background: none;
    animation: right 2s linear infinite;
    animation-delay: 1s;
}
.tabButton .active:nth-child(2)::before {
    background: #05f0fd;
    animation: none;
}
@keyframes right {
    0% {
        background: linear-gradient(transparent, #025cf1, #05f0fd);
        transform: translateY(-100%);
    }
    100% {
        background: linear-gradient(transparent, #025cf1, #05f0fd);
        transform: translateY(100%);
    }
}
.horizontal_faces::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.68px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5));
}
.tabButton:hover .horizontal_faces::after {
    background: none;
    animation: bottom 2s linear infinite;
}
.tabButton .active:nth-child(1)::after {
    background: linear-gradient(270deg, #05f0fd 60%, #025cf1);
    animation: none;
}
@keyframes bottom {
    0% {
        transform: translateX(100%);
        background: linear-gradient(270deg, transparent, #025cf1, #05f0fd);
    }
    100% {
        background: linear-gradient(270deg, transparent, #025cf1, #05f0fd);
        transform: translateX(-100%);
    }
}
.vertical_faces::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1.68px;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
}
.tabButton:hover .vertical_faces::after {
    background: none;
    animation: left 2s linear infinite;
    animation-delay: 1s;
}
.tabButton .active:nth-child(2)::after {
    background: #025cf1;
    animation: none;
}

@keyframes left {
    0% {
        transform: translateY(100%);
        background: linear-gradient(180deg, #05f0fd, #025cf1, transparent);
    }
    100% {
        background: linear-gradient(180deg, #05f0fd, #025cf1, transparent);
        transform: translateY(-100%);
    }
}

.tabsContent {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

@media (max-width: 820px) {
    .tabButton {
        width: 138px;
        height: 44px;
        font-size: 20px;
    }
    .tabButtonsWrapper {
        gap: 10px;
    }
}

@media (max-width: 600px) {
    .tabButton {
        width: 68px;
        height: 29px;
        font-size: 12px;
        line-height: 14px;
    }
}
