.content {
    max-width: 1220px;
    margin: 126px auto 126px auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .content {
        margin: 146px 54px;
    }
    .scrollToHeader {
        right: 20px;
        bottom: 20px;
        transform: scale(0.8);
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .content {
        margin: 126px 54px;
    }
    .scrollToHeader {
        right: 20px;
        bottom: 20px;
        transform: scale(0.7);
    }
}

@media (max-width: 767px) {
    .content {
        margin: 36px 0;
    }
    .scrollToHeader {
        right: 10px;
        bottom: 10px;
        transform: scale(0.6);
    }
}

@media (max-width: 320px) {
    .content {
        margin: 36px 0 30px 0;
    }
    .scrollToHeader {
        right: 0;
        bottom: 0;
        transform: scale(0.5);
    }
}
