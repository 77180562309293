.toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 66px;
    height: 33px;
    padding: 2px;
    box-sizing: border-box;
    border-radius: 21px;
    cursor: pointer;
    &.dark {
        background: #23222a;
        .moon svg {
            animation-name: moon;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0, 0, 1, 1);
            path {
                stroke: #ffffff;
            }
        }
        .sun svg path {
            fill: #0f73ef;
        }
    }
    &.light {
        background: #e3eeff;
        .moon svg path {
            stroke: #0f73ef;
        }
        .sun svg {
            animation-name: sun;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0, 0, 1, 1);
            & path {
                fill: #fbf85d;
            }
        }
    }

    .moon {
        position: absolute;
        left: 11px;
        z-index: 2;
        display: flex;
        align-items: center;
        path {
            transition: 1s;
        }
    }
    .sun {
        position: absolute;
        right: 11px;
        z-index: 2;
        display: flex;
        align-items: center;

        path {
            transition: 1s;
        }
    }
    & .toggleCircle {
        position: absolute;
        z-index: 1;
        left: 3px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        box-sizing: border-box;
        background: #0f73ef;
        border: 3px solid #0f69da;
        animation-duration: 0.4s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
        &.light {
            animation-name: light;
        }
        &.dark {
            animation-name: dark;
        }
    }
}

@keyframes light {
    0% {
        left: 3px;
        transform: rotateX(0deg);
    }
    25% {
        left: 10px;
        transform: rotateX(60deg);
    }
    50% {
        left: 19px;
        transform: rotateX(80deg);
    }
    75% {
        left: 28px;
        transform: rotateX(60deg);
    }

    100% {
        left: 35px;
        transform: rotateX(0deg);
    }
}
@keyframes dark {
    0% {
        left: 35px;
        transform: rotateX(0deg);
    }
    25% {
        left: 28px;
        transform: rotateX(60deg);
    }
    50% {
        left: 19px;
        transform: rotateX(80deg);
    }
    75% {
        left: 10px;
        transform: rotateX(60deg);
    }

    100% {
        left: 3px;
        transform: rotateX(0deg);
    }
}

@keyframes moon {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.2) rotate(15deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

@keyframes sun {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.4) rotate(180deg);
    }
    100% {
        transform: scale(1) rotate(360deg);
    }
}
