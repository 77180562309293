.L {
    top: -40px;
    left: -200px;
    z-index: 1;
    &.loading {
        animation-duration: 6s;
        animation-name: L_Loading;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }

    &.error {
        animation-duration: 6s;
        animation-name: L_Error;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.waiting {
        animation-duration: 6s;
        animation-name: L_Waiting;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
    &.disappear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: L_Disappear;
        animation-timing-function: cubic-bezier(0.92, 0.04, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.appear {
        overflow: hidden;
        animation-duration: 1.5s;
        animation-name: L_Appear;
        animation-timing-function: cubic-bezier(0.52, 0.04, 1, 0.98);
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.grab {
        animation-duration: 1s;
        animation-name: L_Grab;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        // animation-iteration-count: infinite;
    }
    &.success {
        z-index: 3;
        animation-duration: 3s;
        animation-name: L_Success;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;

        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    &.afterWaiting {
        &.appear {
            animation: L_Appear 1.5s forwards, L_Waiting 6s 2.2s infinite;
        }
    }
    &.afterError {
        transform: translate(35px, 350px) rotate(-60deg);

        &.loading {
            animation: L_Restore 3s forwards, L_Loading 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.waiting {
            animation: L_Restore 3s forwards, L_Waiting 6s 1s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            animation-name: L_DisappearAfterError;
            overflow: hidden;
        }
        &.appear {
            animation-name: L_AppearToError;
            overflow: hidden;
        }
    }
    &.afterSuccess {
        transform: translate(167px, 50px) rotate(-120deg);

        &.loading {
            animation: L_Destroy 3s forwards, L_Loading 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }

        &.waiting {
            animation: L_Destroy 3s forwards, L_Waiting 6s 1.2s infinite;
            animation-timing-function: ease-in-out;
        }
        &.disappear {
            z-index: 2;
            animation-name: L_DisappearAfterSuccess;
        }
        &.appear {
            z-index: 3;
            animation-name: L_AppearAfterSuccess;

            &::after {
                display: none;
            }
            &::before {
                display: none;
            }
        }
    }
}
@keyframes L_Loading {
    0% {
        transform: translate(0px, 0px);
    }
    25% {
        transform: translate(30px, 0px);
    }
    50% {
        transform: translate(0px, 0px);
    }
    75% {
        transform: translate(30px, 0px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}
@keyframes L_Error {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    14% {
        transform: translate(0px, 350px) rotate(-105deg);
    }
    14.2% {
        transform: translate(2px, 350px) rotate(-100deg);
    }
    16% {
        transform: translate(5px, 350px) rotate(-95deg);
    }

    35%,
    100% {
        transform: translate(35px, 350px) rotate(-60deg);
    }
}
@keyframes L_Restore {
    0% {
        transform: translate(35px, 350px) rotate(-60deg);
    }

    19% {
        transform: translate(5px, 350px) rotate(-95deg);
    }
    20.8% {
        transform: translate(2px, 350px) rotate(-100deg);
    }
    21% {
        transform: translate(0px, 350px) rotate(-105deg);
    }
    36%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes L_Waiting {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 30px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes L_Success {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15%,
    35% {
        transform: translate(-50px, 0) rotate(0deg);
    }
    50%,
    100% {
        transform: translate(167px, 50px) rotate(-120deg);
    }
}
@keyframes L_Destroy {
    0%,
    10% {
        transform: translate(167px, 50px) rotate(-120deg);
    }
    20% {
        transform: translate(-50px, 0) rotate(0deg);
    }
    35%,
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
@keyframes L_Disappear {
    0% {
        width: 69px;
        transform: translate(0, 0);
    }
    85% {
        width: 69px;
    }
    90%,
    100% {
        width: 0px;
        transform: translate(430px, 0);
    }
}
@keyframes L_DisappearAfterSuccess {
    0% {
        width: 69px;
        transform: translate(167px, 50px) rotate(-120deg);
    }

    78% {
        width: 69px;
    }
    82%,
    100% {
        transform: translate(430px, -50px) rotate(10deg);
        width: 0px;
    }
}
@keyframes L_Appear {
    0%,
    35% {
        width: 0px;
        transform: translate(430px, 0);
        overflow: hidden;
    }
    56% {
        width: 69px;
    }
    85%,
    100% {
        width: 69px;
        transform: translate(0, 0);
    }
}
@keyframes L_AppearToError {
    0%,
    35% {
        width: 0px;
        transform: translate(430px, 0);
        overflow: hidden;
    }
    56% {
        width: 69px;
    }
    85%,
    100% {
        width: 69px;
        transform: translate(35px, 350px) rotate(-60deg);
    }
}
@keyframes L_AppearAfterSuccess {
    0%,
    55% {
        width: 0px;
        transform: translate(430px, 0);
    }
    71% {
        width: 69px;
    }
    75%,
    100% {
        width: 69px;
        transform: translate(167px, 50px) rotate(-120deg);
    }
}
@keyframes L_DisappearAfterError {
    0%,
    45% {
        transform: translate(35px, 350px) rotate(-60deg);
    }
    80% {
        width: 69px;
    }
    92%,
    100% {
        width: 0px;
        transform: translate(430px, 0);
    }
}
@keyframes L_Grab {
    0%,
    20% {
        transform: translate(0, 0);
    }
    40% {
        transform: translate(130px, 20px);
    }
    60% {
        transform: translate(120px, 175px);
    }
    70% {
        transform: translate(120px, 205px);
    }
    76%,
    100% {
        transform: translate(130px, 175px);
    }
}
