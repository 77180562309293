@import './scss';
.gridWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 680px;
    align-self: center;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 800px 1fr;
    grid-auto-rows: 700px;
    align-self: center;
}

.gridWrapper.light {
    .title {
        color: $light-color-blue;
    }
}

.checkBlock {
    display: flex;
    flex-direction: column;
    gap: 75px;
    position: relative;
}

.inputContainer {
    display: grid;
    grid-template-columns: 1fr 178px;
    align-items: center;
    width: 620px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 98px;
    background: $dark-color-blue2;
    background-clip: text;
    color: transparent;
}

.backgroundLines {
    position: absolute;
    left: 0;
    bottom: 300px;
    width: 68px;
    height: 0;
    background-size: cover;
    background-image: url('../../../assets/img/checkBackgroundLines.svg');
    background-repeat: no-repeat;
    background-position: top;
}

.backgroundLines.show {
    animation: showLines 0.5s ease-in-out 1 forwards;
}

@keyframes showLines {
    to {
        height: 332px;
        bottom: 0;
    }
}

.imageBlock {
    position: relative;
    width: 100%;
}

.boxesImage {
    position: absolute;
    width: 100%;
    height: 820px;
    background-image: url('../../../assets/img/boxes.svg');
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
}

.backgroundEllipse {
    position: absolute;
    right: 10%;
    top: -65px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: url('../../../assets/img/backgroundCheck.svg');
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(40px);
}

@media (max-width: 1279px) {
    .imageBlock,
    .backgroundLines {
        display: none;
    }
    .gridWrapper {
        grid-template-columns: auto;
        margin: 0 auto;
        grid-auto-rows: 300px 480px;
        justify-content: center;
    }
    .inputContainer {
        margin: 0 auto;
    }
    .title {
        text-align: center;
    }
}

@media (max-width: 767px) {
    .gridWrapper {
        margin: 0 24px;
        grid-auto-rows: 300px 500px;
    }
    .inputContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .inputContainer > input {
        padding-right: 20px;
    }
    .inputContainer > div:first-child {
        width: 100%;
        border: none;
        background: #252525;
    }
    .inputContainer > div:first-child::before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        border-bottom: 22px solid transparent;
        border-right: 22px solid #12101a;
        border-top: 0 solid transparent;
    }
    .gridWrapper.light {
        & .inputContainer > div:first-child {
            background: #ffffff;
        }
        & .inputContainer > div:first-child::before {
            border-right: 22px solid #f2f4f8;
        }
    }
    .inputContainer > div:first-child {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/img/backgroundCheck.svg');
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 0 24px 0;
    }
    .traffic {
        height: 0;
        overflow: hidden;
    }
    .traffic.show {
        animation: showTraffic 1.5s ease-out 0.4s 1 forwards;
    }
    @keyframes showTraffic {
        to {
            height: 100%;
        }
    }
}

@media (max-width: 580px) {
    .gridWrapper {
        justify-content: center;
    }
    .title {
        font-size: 30px;
        line-height: 38px;
    }
    .checkBlock {
        gap: 25px;
    }
}

@media (max-width: 440px) {
    .inputContainer {
        width: 300px;
    }
}

@media (max-width: 320px) {
    .inputContainer {
        width: 284px;
    }
    .gridWrapper {
        grid-auto-rows: 200px 380px;
        margin: 0 18px;
    }
}
