@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import './scss';
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 100px;
}

.wrapper.light {
    & .title span,
    & .secondDataBlock p,
    & .firstDataBlock span:nth-child(1) {
        color: $light-color-blue;
    }
    & .whitePeers span:first-child {
        background: #f2bf65;
    }
    & .bluePeers span:first-child {
        background: $light-color-blue;
    }
    & .topLine {
        background-image: url('../../../assets/img/topLineLight.svg');
    }
    & .bottomLine {
        background-image: url('../../../assets/img/bottomLineLight.svg');
    }
}

.title {
    width: 552px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 98px;
    overflow: hidden;
    border-right: 2px solid transparent;
    white-space: nowrap;
    animation: typing 2.5s steps(40, end), blink-caret 0.5s step-end 6;
    text-align: center;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 552px;
    }
}

@keyframes blink-caret {
    from,
    to {
        border-color: transparent;
    }

    50% {
        border-color: rgb(189, 189, 189);
    }
}

.title span {
    background-image: $dark-color-blue1;
    background-clip: text;
    color: transparent;
}

.dataBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.firstDataBlock {
    font-size: 61px;
    font-weight: 700;
    position: relative;
    height: 100px;
    width: 220px;
}

.firstDataBlock {
    & .topLine,
    .bottomLine {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    & p {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: baseline;
    }
}
.firstDataBlock span:nth-child(1) {
    font-size: 61px;
    font-weight: 700;
    padding: 0 10px 0 0;
}

.firstDataBlock span:nth-child(2) {
    font-size: 24px;
    color: $dark-color-third;
    font-weight: 600;
}

.secondDataBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.5fr 1fr;
    grid-gap: 5px 100px;
}

.secondDataBlock p {
    font-size: 48px;
    font-weight: 700;
}

.secondDataBlock span {
    font-size: 24px;
    color: $dark-color-third;
    font-weight: 600;
}

.image {
    z-index: 2;
    width: 100%;
    height: 596.51px;
    background-image: url('../../../assets/img/map.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.topLine {
    width: 222px;
    height: 103px;
    background-image: url('../../../assets/img/topLine.svg');
    background-position: center;
    background-repeat: no-repeat;
}

.bottomLine {
    width: 222px;
    height: 103px;
    background-image: url('../../../assets/img/bottomLine.svg');
    background-position: center;
    background-repeat: no-repeat;
}

.peers {
    font-family: 'Gotham Pro';
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
}

.whitePeers span:first-child,
.bluePeers span:first-child {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    margin: 0 12px 0 0;
}

.whitePeers span:first-child {
    background: #ffffff;
}

.bluePeers span:first-child {
    background: linear-gradient(90.31deg, rgba(6, 240, 254, 0.94) 6.26%, rgba(2, 92, 241, 0.94) 118.81%);
}

.whitePeers {
    margin: 0 20px 0 0;
}

.whitePeers,
.bluePeers {
    display: flex;
    align-items: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .firstDataBlock {
        font-size: 50px;
        line-height: 34px;
    }

    .firstDataBlock span {
        font-size: 24px;
        line-height: 30px;
    }

    .secondDataBlock p {
        font-size: 43px;
        line-height: 54px;
    }

    .secondDataBlock span {
        font-size: 20px;
        line-height: 25px;
    }

    .secondDataBlock {
        grid-gap: 0px 40px;
        margin-left: 40px;
    }
}

@media (max-width: 767px) {
    .dataBlock {
        flex-direction: column;
    }

    .firstDataBlock > p {
        font-size: 38px;
        line-height: 25px;
        width: 160px;
        text-align: center;
    }

    .firstDataBlock > p > span {
        font-size: 18px;
        line-height: 23px;
    }

    .bottomLine {
        width: 160px;
        background-position-x: left;
    }

    .topLine {
        width: 160px;
        background-position-x: right;
    }

    .secondDataBlock {
        margin-top: 24px;
        margin-left: 62px;
        margin-right: 62px;
        grid-gap: 5px 60px;
    }

    .secondDataBlock > span {
        font-size: 18px;
        line-height: 23px;
    }

    .secondDataBlock > p {
        font-size: 38px;
        line-height: 48px;
    }

    .peers {
        justify-content: center;
    }
}

/*  */

@media (max-width: 580px) {
    .title {
        font-size: 38px;
        line-height: 48px;
        width: 263px;
        animation: typing 2.5s steps(40, end), blink-caret 0.5s step-end 6;
    }

    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 263px;
        }
    }
}

@media (max-width: 320px) {
    .wrapper {
        margin: 0 20px;
        gap: 0;
    }

    .title {
        font-size: 34px;
        line-height: 43px;
        width: 235px;
        animation: typing 2.5s steps(40, end), blink-caret 0.5s step-end 6;
    }

    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 235px;
        }
    }

    .firstDataBlock {
        margin-top: 30px;
    }

    .secondDataBlock {
        grid-gap: 5px 30px;
        margin: 30px 0 0 0;
    }

    .secondDataBlock > span {
        font-size: 14px;
        line-height: 18px;
    }

    .secondDataBlock > p {
        font-size: 34px;
        line-height: 43px;
    }
}

.fontRoboto {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}
