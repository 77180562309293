@import './scss';
.wrapper {
    margin: 0 0 92px 0;
}
.wrapper.light {
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        gap: 50px;
        width: 184px;
        height: 173px;
        background: white;
    }
    .final_kb {
        counter-reset: num var(--num);
        animation-name: counterLight;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.24, 0.9, 0.61, 0.54);
    }
    .gridCell:nth-child(even) {
        background-color: #2f2a360a;
    }
}

.firstBlock {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.titleWrap {
    // width: 660px;
}

.title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
}

.title .peerId {
    display: inline-block;
    width: 590px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.title div:first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #bfcbe1;
    margin: 0 5px 0 0;
    white-space: nowrap;
}

.speedometersBlock {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    width: 400px;
}

.incoming {
    position: relative;
    width: 100%;
    height: 200px;
    background-image: url('../../../assets/img/Incoming.svg');
    background-position: center;
    background-repeat: no-repeat;
}

.boxes {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 50px;
    width: 184px;
    height: 173px;
    background: #1a1922;
}

.box:nth-child(1),
.box:nth-child(2),
.box:nth-child(3) {
    background-position: center;
    background-repeat: no-repeat;
}

.box:nth-child(1) {
    background-image: url('../../../assets/img/boxOneBorder.svg');
}

.box:nth-child(2) {
    background-image: url('../../../assets/img/boxTwoBorder.svg');
}

.box:nth-child(3) {
    background-image: url('../../../assets/img/boxThreeBorder.svg');
}
.box:nth-child(4) {
    background-image: url('../../../assets/img/boxTwoBorder.svg');
}

.box:nth-child(5) {
    background-image: url('../../../assets/img/boxOneBorder.svg');
}

.box p {
    font-weight: 700;
    font-size: 32px;
}

.box span {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    color: #bfcbe1;
}

.scheduleImage {
    position: relative;
    width: 100%;
    height: 590px;
    background-image: url('../../../assets/img/schedule.svg');
    background-position: center;
    background-repeat: no-repeat;
}

.accuredRewardsBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gridTable {
    display: grid;
    grid-template-columns: 522px;
    grid-auto-rows: 1fr;
}

.gridTitle,
.gridCell {
    display: grid;
    grid-template-columns: 300px 222px;
    font-weight: 400;
}

.gridTitle {
    font-size: 12px;
    font-weight: 400;
    color: #7c7e88;
    padding: 18px 0 8px 0;
    align-items: end;
}

.gridCell {
    font-size: 14px;
    padding: 18px 0;
}

.gridCell:nth-child(even) {
    background-color: #2f2a36;
}

.gridCell p:nth-child(odd),
.gridTitle p:nth-child(odd) {
    padding-left: 80px;
}

.gridCell p:nth-child(even) {
    color: $dark-color-second;
}

.speedometer_container {
    font-family: 'Plus Jakarta Sans';
    color: #ffffff;
    font-weight: 700;
    position: relative;
    width: 192px;
    height: 193px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background {
    width: 192px;
    height: 193px;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.speedometer {
    border-radius: 100%;
    background-image: conic-gradient(from -117deg, var(--color) var(--visibility), #687388 0%);
    width: 191px;
    height: 191px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: conic-gradient;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.24, 0.9, 0.61, 0.54);
}

.speedometer_type_container {
    z-index: 2;
    text-align: center;
}

.type {
    font-size: 16px;
    color: #687388;
}

.kilobyte {
    font-size: 32px;
}

.final_kb {
    counter-reset: num var(--num);
    animation-name: counter;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.24, 0.9, 0.61, 0.54);
}

.final_kb::after {
    content: counter(num);
    margin: 0 4px 0 0;
}

.kb {
    font-size: 16px;
    background: $dark-color-blue2;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@property --num {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
}

@property --visibility {
    syntax: '<percentage>';
    initial-value: 0%;
    inherits: false;
}

@keyframes counter {
    from {
        --num: 0;
        opacity: 0;
        color: var(--color);
    }

    to {
        --num: var(--final-kilobyte);
        opacity: 1;
        color: #fff;
    }
}
@keyframes counterLight {
    from {
        --num: 0;
        opacity: 0;
        color: var(--color);
    }

    to {
        --num: var(--final-kilobyte);
        opacity: 1;
        color: black;
    }
}

@keyframes conic-gradient {
    from {
        --visibility: 0%;
    }

    to {
        --visibility: var(--final-percentage);
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .firstBlock {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .firstBlock > div:first-child {
        margin-bottom: 60px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .firstBlock {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .firstBlock > div:first-child {
        margin-bottom: 60px;
    }
}

@media (max-width: 767px) {
    .bandWidth {
        margin: 25px;
    }

    .titleWrap {
        width: calc(100% - 10px);
    }

    .firstBlock {
        display: flex;
        flex-direction: column;
        margin: 25px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .speedometersBlock {
        justify-content: space-between;
        width: auto;
        margin-bottom: 30px;
    }

    .box span {
        font-size: 24px;
        letter-spacing: -0.02em;
    }

    .box span {
        font-size: 12px;
        line-height: 17px;
    }
}

@media (max-width: 560px) {
    .gridTable {
        grid-template-columns: 430px;
        grid-auto-rows: 1fr;
    }

    .gridTitle,
    .gridCell {
        grid-template-columns: 55% 45%;
    }

    .gridCell p:nth-child(odd),
    .gridTitle p:nth-child(odd) {
        padding-left: 40px;
    }
}

@media (max-width: 440px) {
    .gridTable {
        grid-template-columns: 310px;
        grid-auto-rows: 1fr;
    }

    .gridTitle,
    .gridCell {
        grid-template-columns: 55% 45%;
    }

    .gridCell p:nth-child(odd),
    .gridTitle p:nth-child(odd) {
        padding-left: 20px;
    }

    .title {
        font-size: 18px;
    }

    .title > span {
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        text-transform: capitalize;
        text-align: left;
    }

    .firstBlock {
        margin: 14px;
    }

    .bandWidth {
        margin: 14px;
    }

    .speedometersBlock {
        flex-direction: column;
    }

    .speedometer_container {
        margin: auto;
    }

    .boxes {
        flex-direction: column;
    }

    .box {
        margin: auto;
        width: 184px;
        height: 173px;
        gap: 50px;
    }

    .box span {
        font-size: 16px;
        line-height: 24px;
        color: #bfcbe1;
    }

    .accuredRewardsBlock {
        margin: 0 14px;
    }
}

@media (max-width: 320px) {
    .gridTable {
        grid-template-columns: 280px;
        grid-auto-rows: 1fr;
    }

    .gridCell {
        font-size: 12px;
    }
}
