@import './scss';
.wrapper {
    margin: 192px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 104px;
    position: relative;
}

.wrapper.light {
    & .title span {
        color: $light-color-blue;
    }
    & .description {
        color: $light-color-second;
    }
    & .icon path {
        fill: $light-color-blue;
    }
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 98px;
    margin: 0 auto;
    overflow: hidden;
    border-right: 2px solid transparent;
    animation: typing 2.5s steps(40, end), blink-caret 0.5s step-end 6;
    white-space: nowrap;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 720px;
    }
}

@keyframes blink-caret {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: rgb(189, 189, 189);
    }
}

.informationBlocks {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 278px 278px;
    grid-auto-rows: 190px;
    grid-gap: 50px;
    justify-content: space-between;
}

.informationBlock {
    width: 278px;
    display: grid;
    grid-template-rows: 30px 1fr;
    grid-row-gap: 29px;
    grid-column-gap: 16px;
    grid-template-areas: ' icon title' 'icon description ';
}

.titleBlock {
    font-size: 24px;
    font-weight: 700;
    grid-area: title;
    line-height: 30px;
    white-space: pre-line;
}

.description {
    line-height: 24px;
    white-space: pre-line;
    font-size: 16px;
    font-weight: 400;
    color: $dark-color-second;
    letter-spacing: 1px;
    grid-area: description;
    color: $dark-color-second;
}
.tree {
    position: absolute;
    top: 183px;
    width: 441px;
    height: 635.81px;
    z-index: 1;
}
.field {
    position: absolute;
    top: 477px;
    width: 1109px;
    height: 488px;
}

@media (max-width: 1024px) {
    .tree {
        display: none;
    }
    .field {
        display: none;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .wrapper {
        margin: 140px 0 192px 0;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .wrapper {
        margin: 100px 0 0 0;
    }
    .title {
        font-size: 63px;
        animation: typing 2.5s steps(40, end), blink-caret 0.5s step-end 6;
    }
    @keyframes typing {
        from {
            width: 0;
        }
        to {
            width: 570px;
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        margin: 100px 0 0 0;
        gap: 64px;
    }
    .title {
        font-size: 38px;
        line-height: 48px;
        animation: typing 2.5s steps(40, end), blink-caret 0.5s step-end 6;
        white-space: pre-line;
        text-align: center;
    }
    .informationBlocks {
        grid-template-columns: 1fr;
        justify-items: center;
        grid-auto-rows: auto;
    }
    .informationBlock {
        grid-row-gap: 5px;
    }
    @keyframes typing {
        from {
            width: 0;
        }
        to {
            width: 350px;
        }
    }
}

@media (max-width: 320px) {
    .title {
        font-size: 30px;
        line-height: 38px;
        width: 270px;
        animation: typing 2.5s steps(40, end), blink-caret 0.5s step-end 6;
    }
    @keyframes typing {
        from {
            width: 0;
        }
        to {
            width: 270px;
        }
    }
}
