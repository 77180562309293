@import './scss';
.wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.inputWrapper {
    display: flex;
    position: relative;
    box-sizing: border-box;
    background-color: $input-background;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.inputWrapper::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: -1px;
    border-top: 22px solid transparent;
    border-left: 22px solid #12101a;
    border-bottom: 0 solid transparent;
}
.inputWrapper .input:focus {
    background: linear-gradient(45deg, #06f0fe 15px, $input-background 0) bottom left;
}

.inputWrapper.light {
    background-color: #ffffff;

    &::after {
        border-left: 22px solid #f2f4f8;
    }

    & input {
        color: #4b576c;
    }

    & input::placeholder {
        color: #7e8a9f;
    }

    &.wrapperFocus-error .input:focus {
        background: linear-gradient(45deg, $input-error-light1 15px, #fff7f7 0) bottom left;
    }

    & .input-error {
        background: linear-gradient(-135deg, #fff7f7 15px, #fff7f7 0) top right,
            linear-gradient(45deg, transparent 15px, #fff7f7 0) bottom left;
        border-right: 0;
        background-size: 100% 50%;
        background-repeat: no-repeat;
        color: $input-error-light1;
        &::placeholder {
            color: $input-error-light1;
        }
    }
}

.wrapperFocus-loading,
.wrapperFocus-success,
.wrapperFocus-primary,
.wrapperFocus-check {
    &.light {
        & .input:focus {
            background: linear-gradient(45deg, $light-color-blue 15px, rgb(255, 255, 255) 0) bottom left;
            border: 1px solid $light-color-blue;
            border-right: 0;
        }
    }
}

.wrapperFocus-loading,
.wrapperFocus-success,
.wrapperFocus-primary,
.wrapperFocus-check {
    & .input:focus {
        border: 1px solid #06f0fe;
        border-right: 0;
    }
}

.wrapperFocus-error .input:focus {
    border: 1px solid $input-error-dark;
    border-right: 0;
}

.inputWrapper.wrapperFocus-error .input:focus {
    background: linear-gradient(45deg, $input-error-dark 15px, $input-background 0) bottom left;
    color: #b70a0af0;
}

.input:focus .inputWrapper .input:focus {
    background: linear-gradient(45deg, #06f0fe 15px, $input-background 0) bottom left;
}

.wrapper-error {
    background: linear-gradient(-135deg, transparent 15px, $input-error-dark 0) top right,
        linear-gradient(45deg, transparent 15px, $input-error-dark 0) bottom left;
    background-size: 100% 60%;
    background-repeat: no-repeat;
}

.wrapper-warning {
    background: linear-gradient(-135deg, transparent 15px, $input-error-light 0) top right,
        linear-gradient(45deg, transparent 15px, $input-error-light 0) bottom left;
    background-size: 100% 60%;
    background-repeat: no-repeat;
}

.input {
    width: 100%;
    height: 64px;
    font-size: 16px;
    color: $dark-color-second;
    background: transparent;
    border: 1px solid transparent;
    outline: none;
    box-sizing: border-box;
    padding: 0 30px 0 20px;
    font-family: 'Roboto', sans-serif;
    text-overflow: ellipsis;
}

.input::placeholder {
    color: $dark-color-second;
}

.input-error {
    background: linear-gradient(-135deg, $input-error-dark1 15px, $input-error-dark1 0) top right,
        linear-gradient(45deg, transparent 15px, $input-error-dark1 0) bottom left;
    border-right: 0;
    background-size: 100% 50%;
    background-repeat: no-repeat;
}

.input-warning {
    background: linear-gradient(-135deg, transparent 15px, $input-error-dark2 0) top right,
        linear-gradient(45deg, transparent 15px, $input-error-dark2 0) bottom left;
    background-size: 100% 50%;
    background-repeat: no-repeat;
}

.errorMessage {
    position: absolute;
    bottom: -25px;
    font-size: 12px;
    color: #ff4a4a;
}

@media (max-width: 930px) {
    .input {
        padding: 0 32px 0 20px;
    }

    .inputWrapper.wrapperFocus-primary,
    .inputWrapper.wrapperFocus-success,
    .inputWrapper.wrapperFocus-loading,
    .inputWrapper.wrapperFocus-check {
        &.light {
            & .input:focus {
                background: linear-gradient(45deg, $light-color-blue 15px, transparent 0) bottom left,
                    linear-gradient(-135deg, $light-color-blue 15px, transparent 0) top right;
                border: 1px solid $light-color-blue;
            }
        }
    }

    .inputWrapper.wrapperFocus-error {
        &.light {
            & .input {
                background: linear-gradient(45deg, $input-error-light1 15px, transparent 0) bottom left,
                    linear-gradient(-135deg, $input-error-light1 15px, #fff7f7 0) top right;
                border: 1px solid $input-error-light1;

                &:focus {
                    background: linear-gradient(45deg, $input-error-light1 15px, transparent 0) bottom left,
                        linear-gradient(-135deg, $input-error-light1 15px, #fff7f7 0) top right;
                }
            }
        }
    }

    .wrapperFocus-primary,
    .wrapperFocus-success,
    .wrapperFocus-loading,
    .wrapperFocus-check {
        & .input:focus {
            background: linear-gradient(45deg, #06f0fe 15px, transparent 0) bottom left,
                linear-gradient(-135deg, #06f0fe 15px, transparent 0) top right;

            border: 1px solid #06f0fe;
        }
    }

    .wrapperFocus-error .input:focus {
        background: linear-gradient(45deg, $input-error-dark 15px, transparent 0) bottom left,
            linear-gradient(-135deg, $input-error-dark 15px, #280505 0) top right;
        border: 1px solid $input-error-dark;
    }

    .inputWrapper.wrapperFocus-error .input:focus {
        background: linear-gradient(45deg, $input-error-dark 15px, transparent 0) bottom left,
            linear-gradient(-135deg, $input-error-dark 15px, #280505 0) top right;
    }

    .errorMessage {
        bottom: -15px;
        left: 30px;
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .input.footer {
        height: 64px;
    }
}
